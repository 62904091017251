import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Table, TableHead } from '@mui/material';
import { DeltaCell, roundWell, ValueCell } from '../components/CellUtils';

const borderColor = '#010101';

const useStyles = makeStyles()((theme) => {
    return {
        tableContainer: {
            marginTop: theme.spacing(2),
            overflowX: 'auto',
            width: '100%',
            minHeight: '400px'
        },
        tableHead:{
            background: '#d6dce4',
            whiteSpace: 'nowrap',
            
            '& th': {
                textAlign: 'center',
                fontWeight: '700',
                borderTop: `1px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                borderBottom: `1px solid ${borderColor}`,
            },
        
            '& th:first-of-type': {
                borderLeft: `1px solid ${borderColor}`,
            },
        },
        differentRows: {
            overflowWrap: 'anywhere',

            '& tr:nth-of-type(even)': {
                backgroundColor: '#d9d9d9',
            },

            '& tr.italic': {
                fontStyle: 'italic',
            },

            '& tr.total': {
                backgroundColor: '#b4c6e7',

                '& td': {
                    fontWeight: '700',
					cursor: 'auto',
                },
            },

            '& td': {
                borderRight: `1px solid ${borderColor}`,
                borderBottom: `1px solid ${borderColor}`,
                whiteSpace: 'nowrap',
                padding: 0,
                paddingLeft: '2px',
                paddingRight: '2px',
				cursor: 'pointer',
            },

            '& td:not(:first-of-type)': {
                textAlign: 'end',
            },

            '& td:first-of-type': {
                borderLeft: `1px solid ${borderColor}`,
            },

            '& td:not(.delta)': {
            },

            '& td.delta': {
                textAlign: 'center',

                '&.positive': {
                    backgroundColor: '#c6e0b4',
                },

                '&.negative': {
                    backgroundColor: '#fa9a9a',
                },
            },
        },
        loading: {
            textAlign: 'center',
            height: '50vh',
            color: '#777',
            fontSize: 72,
            fontStyle: 'italic',
        },
		tab: {
            marginLeft: theme.spacing(3),
			breakWord: 'break-all',
        },
    }
});

export default function StatisticsSellingsTable({ data, activity, delta }) {
    const { classes } = useStyles();
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    return (
        <div className={classes.tableContainer}>
        <Table>
            <TableHead>
                <TableRow className={classes.tableHead}>
                    <TableCell>Mois</TableCell>
                    <TableCell>Nouveaux Comptes</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>Nouveaux comptes actifs</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>Total comptes actifs</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>Total envoi</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>Moyen envoi / jour</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>CA</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>MB</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>CA cumulé sur {activity} mois</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }
                    <TableCell>MB cumulée sur {activity} mois</TableCell>
                    { delta ? <TableCell>Δ%</TableCell> : null }

                </TableRow>
            </TableHead>
            <TableBody className={classes.differentRows}>
                {data.map((row, index) => (
                    <React.Fragment key={index}>
                        <TableRow onClick={() => handleRowClick(index)}>
                            <ValueCell>{row.month} {row.year}</ValueCell>
                            <ValueCell>{roundWell(row.content.total_created_accounts)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.total_created_accounts_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.new_active_clients)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.new_active_clients_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.total_active_clients)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.total_active_clients_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.total_shipments)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.total_shipments_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.avg_shipments)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.avg_shipments_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.ca_total)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.ca_total_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.mb_total)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.mb_total_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.ca_cumulated)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.ca_cumulated_delta)} /> : null }
                            <ValueCell>{roundWell(row.content.mb_cumulated)}</ValueCell>
                            { delta ? <DeltaCell value={roundWell(row.content.mb_cumulated_delta)} /> : null }
                        </TableRow>
                        {expandedRow === index && (
                            <TableRow>
								{ console.log(row.content.clients) }
                                <TableCell colSpan={delta ? 7 : 4}>
                                    <div>
                                        {row.content.clients.map((client, clientIndex) => (
                                            <div key={clientIndex} className={classes.tab}>
                                                {client.new_client ? <b>Nouveau client - </b> : ''} {client.inactive ? <b>(Inactif)</b> : ''} {client.client_name}
                                            </div>
                                        ))}
                                    </div>
                                </TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.total_shipments)}
										</div>
									))}
								</TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.avg_shipments)}
										</div>
									))}
								</TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.ca)}
										</div>
									))}
								</TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.mb)}
										</div>
									))}
								</TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.ca_cumulated)}
										</div>
									))}
								</TableCell>
								<TableCell>
									{row.content.clients.map((client, clientIndex) => (
										<div key={clientIndex}>
											{roundWell(client.mb_cumulated)}
										</div>
									))}
								</TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                ))}

                {/* Total */}
                {data.length > 0 && (
                    <TableRow className="total">
                        <ValueCell>Total</ValueCell>
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_created_accounts, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.new_active_clients, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_active_clients, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_shipments, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.avg_shipments, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_total, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_total, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_cumulated, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                        <ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_cumulated, 0))}</ValueCell>
                        { delta ? <ValueCell></ValueCell> : null }
                    </TableRow>
                )}
            </TableBody>
        </Table>
        </div>
    )
}