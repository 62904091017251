import _ from 'lodash';
import { getMonthFrench } from './constantsFR';
import { logOut } from './Frame';

// Filter array by searchInput
export function filterBySearchInput(arr, searchInput) {
  if(arr.length) {
    return arr.filter(item => Object.values(item).toString().toLowerCase().includes(searchInput.toLowerCase()));
  }

  return [];
}

// Convert Date to Unix Timestamp
export function convertDateToTimestamp(date) {
  let timeStamp = 0;
  if(date || _.isNumber(date)) {
    const dateInMilliseconds = (new Date(date)).getTime();
    timeStamp = Math.trunc(dateInMilliseconds / 1000);
  }

  return timeStamp;
}

// Convert from any date to 16 avril 2021
export function convertDateToFrench(params) {
  let dateFrench = '';
  if(params || _.isNumber(params)) {
    dateFrench = `${(new Date(params)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params)} ${(new Date(params)).getFullYear().toString()}`;
  }

  return dateFrench;
}

// Convert date to 16.04.2021
export function convertDateToWithDots(params) {
  let dateWithDots = '';
  if(params || _.isNumber(params)) {
    dateWithDots = `${(new Date(params)).getDate().toString().padStart(2, '0')}.${((new Date(params)).getMonth() + 1).toString().padStart(2, '0')}.${(new Date(params)).getFullYear().toString()}`;
  }

  return dateWithDots;
}

// Convert date to hh:mm (hours & minutes)
export function convertHoursAndMinutes(params) {
  let hoursAndMinutes = '';
  if(params || _.isNumber(params)) {
    hoursAndMinutes = `${(new Date(params)).getHours().toString().padStart(2, '0')}:${(new Date(params)).getMinutes().toString().padStart(2, '0')}`;
  }

  return hoursAndMinutes;
}

// Convert date to 2021-04-16
export function convertDateToWithDash(params) {
  let dateWithDots = '';
  if(params || _.isNumber(params)) {
    dateWithDots = `${(new Date(params)).getFullYear().toString()}-${((new Date(params)).getMonth() + 1).toString().padStart(2, '0')}-${(new Date(params)).getDate().toString().padStart(2, '0')}`;
  }

  return dateWithDots;
}

// Current users id
export function currentUserId(users) {
  let userId = 0;

  if(users.length) {
    const username = localStorage.getItem('username');
    if(!username) logOut();
    const findUser = users.filter(user => (user.username.toLowerCase() === username.toLowerCase()));
    userId = findUser[0].id;
  }

  return userId;
}

// Number Two Decimal ("23.6000000" => "23.60")
export function numberTwoDecimal(string) {
  if(Number.isNaN(Number.parseFloat(string))) {
    return '';
  }

  return seperateThousands(Number.parseFloat(string).toFixed(2));
}

// Check if object is exist 
export function checkObject(object) {
  if(object) {
    if(Object.values(object).length) {
      return true;
    }
    return false;
  }

  return false;
}

// Seperate numbers every thousands with ’ 
export function seperateThousands(x) {
  if(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "’");
  } else {
    return x;
  }
}

// à faire + en cours (merging task statuses)
// countCommentsTasks, tracking.id
export function countUndoneTasks(countTasks, trackingId, tabValue) {
  if(countTasks[`${trackingId}`] !== undefined) {
    if(tabValue === 1) {
      return (countTasks[`${trackingId}`]['nbr_taskwith_1'] * 1);

    } else if(tabValue === 2) {
      return (countTasks[`${trackingId}`]['nbr_taskwith_2'] * 1);

    } else if(tabValue === 3) {
      return (countTasks[`${trackingId}`]['nbr_taskwith_3'] * 1);
    }

    return (countTasks[`${trackingId}`]['nbr_tasks_1'] * 1 + countTasks[`${trackingId}`]['nbr_tasks_2'] * 1);
  }

  return 0;
}

// count comments, chats... (countCommentsTasks, tracking.id, 'nbr_comment')
export function countNbr(arrNbr, shipId, keyObj) {
  if(arrNbr[shipId]) {
    return arrNbr[shipId][keyObj];
  }

  return 0;
}

// Calculate percentage a / b * 100
export function calcPercentage(a, b) {
  if(a === 0 | b === 0) { return null; }
  if(isNaN(a) |isNaN(b)) { return null; }

  const c = (a-b)/b;
  const d = Math.abs(c);
  const result = Math.round(d * 100);
  return result;
}

// First calculate percentage, then returns 'positive', 'negative' or ''
export function signOfPercentage(a, b) {
  if(a === 0 | b === 0) { return ''; }
  if(isNaN(a) |isNaN(b)) { return ''; }

  let result = '';
  const c = (a-b)/b;
  
  if(c === Infinity) { result = ''; }
  if(c > 0) { result = 'positive'; }
  if(c < 0) { result = 'negative'; }

  return result;
}

// Eori or  Vat or Passport Number
export const customNumber = shipment => {
  const general = 'EORI Number/VAT Number/Passport Number';
  const eori = 'EORI Number';
  const vat = 'VAT Number';
  const passport = 'Passport Number';

  if(shipment.delivery_company_name === undefined || shipment.delivery_company_name === null) return passport;

  if(shipment.is_delivery_eu === undefined || shipment.is_delivery_eu === null) return general;
  
  if(shipment.delivery_company_name.length && shipment.is_delivery_eu === 'True') {
    return eori;
  } else if (shipment.delivery_company_name.length && shipment.is_delivery_eu === 'False') {
    return vat;
  } else if (!shipment.delivery_company_name.length) {
    return passport;
  }
  return general;
};
