import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Table, TableHead } from '@mui/material';
import { DeltaCell, roundWell, ValueCell } from '../components/CellUtils';

const borderColor = '#010101';

const useStyles = makeStyles()((theme) => {
	return {
		tableContainer: {
			marginTop: theme.spacing(2),
			overflowX: 'auto',
			width: '100%',
			minHeight: '400px'
		},
		tableHead:{
			background: '#d6dce4',
			whiteSpace: 'nowrap',
			
			'& th': {
				textAlign: 'center',
				fontWeight: '700',
				borderTop: `1px solid ${borderColor}`,
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
			},
		
			'& th:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		differentRows: {
			overflowWrap: 'anywhere',

			'& tr:nth-of-type(even)': {
				backgroundColor: '#d9d9d9',
			},

			'& tr.italic': {
				fontStyle: 'italic',
			},

			'& tr.total': {
				backgroundColor: '#b4c6e7',

				'& td': {
					fontWeight: '700',
				},
			},

			'& td': {
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
				whiteSpace: 'nowrap',
				padding: 0,
				paddingLeft: '2px',
				paddingRight: '2px'
			},

			'& td:not(:first-of-type)': {
				textAlign: 'end',
			},

			'& td:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},

			'& td:not(.delta)': {
			},

			'& td.delta': {
				textAlign: 'center',

				'&.positive': {
					backgroundColor: '#c6e0b4',
				},

				'&.negative': {
					backgroundColor: '#fa9a9a',
				},
			},
		},
		loading: {
			textAlign: 'center',
			height: '50vh',
			color: '#777',
			fontSize: 72,
			fontStyle: 'italic',
		},
	}
});

export default function StatisticsInsurancesTable({ data, isAllInsurances, isPercent }) {
	const { classes } = useStyles();

	return (
		<div className={classes.tableContainer}>
		<Table>
			<TableHead>
				<TableRow className={classes.tableHead}>
					<TableCell>Mois</TableCell>
					<TableCell>Souscription assurances {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Documents {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Colis {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Palettes {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>CA {isPercent ? "(%)" : "(CHF)"}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>MB {isPercent ? "(%)" : "(CHF)"}</TableCell>
					<TableCell>Δ%</TableCell>
					</TableRow>
			</TableHead>
			<TableBody className={classes.differentRows}>
				{data.map((row, index) => (
					<TableRow key={index}>
						<ValueCell>{row.month} {row.year}</ValueCell>
						<ValueCell>{roundWell(row.content.total_insurances)}</ValueCell>
						<DeltaCell value={roundWell(row.content.total_insurances_delta)} />
						<ValueCell>{roundWell(row.content.documents)}</ValueCell>
						<DeltaCell value={roundWell(row.content.documents_delta)} />
						<ValueCell>{roundWell(row.content.parcels)}</ValueCell>
						<DeltaCell value={roundWell(row.content.parcels_delta)} />
						<ValueCell>{roundWell(row.content.pallets)}</ValueCell>
						<DeltaCell value={roundWell(row.content.pallets_delta)} />
						<ValueCell>{roundWell(row.content.ca_insurance)}</ValueCell>
						<DeltaCell value={roundWell(row.content.ca_insurance_delta)} />
						<ValueCell>{roundWell(row.content.mb_insurance)}</ValueCell>
						<DeltaCell value={roundWell(row.content.mb_insurance_delta)} />
					</TableRow>
				))}
			</TableBody>
		</Table>
		</div>
	)
}