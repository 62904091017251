import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Table, TableHead } from '@mui/material';
import { DeltaCell, roundWell, ValueCell } from '../components/CellUtils';

const borderColor = '#010101';

const useStyles = makeStyles()((theme) => {
	return {
		tableContainer: {
			marginTop: theme.spacing(2),
			overflowX: 'auto',
			width: '100%',
			minHeight: '400px'
		},
		tableHead:{
			background: '#d6dce4',
			whiteSpace: 'nowrap',
			
			'& th': {
				textAlign: 'center',
				fontWeight: '700',
				borderTop: `1px solid ${borderColor}`,
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
			},
		
			'& th:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		differentRows: {
			overflowWrap: 'anywhere',

			'& tr:nth-of-type(even)': {
				backgroundColor: '#d9d9d9',
			},

			'& tr.italic': {
				fontStyle: 'italic',
			},

			'& tr.total': {
				backgroundColor: '#b4c6e7',

				'& td': {
					fontWeight: '700',
				},
			},

			'& td': {
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
				whiteSpace: 'nowrap',
				padding: 0,
				paddingLeft: '2px',
				paddingRight: '2px'
			},

			'& td:not(:first-of-type)': {
				textAlign: 'end',
			},

			'& td:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},

			'& td:not(.delta)': {
			},

			'& td.delta': {
				textAlign: 'center',

				'&.positive': {
					backgroundColor: '#c6e0b4',
				},

				'&.negative': {
					backgroundColor: '#fa9a9a',
				},
			},
		},
		loading: {
			textAlign: 'center',
			height: '50vh',
			color: '#777',
			fontSize: 72,
			fontStyle: 'italic',
		},
	}
});

export default function StatisticsDashboardTable({ data, pareto, isPercent, delta }) {
	const { classes } = useStyles();

	return (
		<div className={classes.tableContainer}>
		<Table>
			<TableHead>
				<TableRow className={classes.tableHead}>
					{ pareto ? <TableCell>Top</TableCell> : <TableCell>Mois</TableCell> }
					{ pareto ? <TableCell>Nom du client</TableCell> : null }
					<TableCell>CA total (CHF)</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>MB totale {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>CA Envois {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>MB Envois {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>CA Assur. {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>MB Assur. {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Envois moyen par jour</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Nb documents {isPercent ? "(%)" : null}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Nb colis {isPercent ? "(%)" : null}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Total des envois</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					{ pareto ? null : <TableCell>Totalité des clients actifs</TableCell> }
					{ delta ? <TableCell>Δ%</TableCell> : null }
					{ pareto ? null : <TableCell>Totalité des comptes créés</TableCell> }
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>CA Offres perso. {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>MB Offres perso. {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Discount {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>Surfacturation {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					<TableCell>DDP {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ delta ? <TableCell>Δ%</TableCell> : null }
					</TableRow>
			</TableHead>
			<TableBody className={classes.differentRows}>
				{data.map((row, index) => (
					<TableRow key={index}>
						{
							pareto ? <ValueCell>{index + 1}</ValueCell> : <ValueCell>{row.month} {row.year}</ValueCell>
						}
						{
							pareto ? <ValueCell>{row.content.client_name}</ValueCell> : null
						}
						<ValueCell>{roundWell(row.content.ca_total)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.ca_total_delta)} /> : null }
						<ValueCell>{roundWell(row.content.mb_total)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.mb_total_delta)} /> : null }
						<ValueCell>{roundWell(row.content.ca_shipment)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.ca_shipment_delta)} /> : null }
						<ValueCell>{roundWell(row.content.mb_shipment)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.mb_shipment_delta)} /> : null }
						<ValueCell>{roundWell(row.content.ca_insurance)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.ca_insurance_delta)} /> : null }
						<ValueCell>{roundWell(row.content.mb_insurance)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.mb_insurance_delta)} /> : null }
						<ValueCell>{roundWell(row.content.avg_shipments)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.avg_shipments_delta)} /> : null }
						<ValueCell>{roundWell(row.content.nb_docs)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.nb_docs_delta)} /> : null }
						<ValueCell>{roundWell(row.content.nb_parcels)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.nb_parcels_delta)} /> : null }
						<ValueCell>{roundWell(row.content.total_shipments)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.total_shipments_delta)} /> : null }
						{ pareto ? null : <ValueCell>{roundWell(row.content.total_active_clients)}</ValueCell> }
						{ delta ? <DeltaCell value={roundWell(row.content.total_active_clients_delta)} /> : null }
						{ pareto ? null : <ValueCell>{roundWell(row.content.total_created_accounts)}</ValueCell> }
						{ delta ? <DeltaCell value={roundWell(row.content.total_created_accounts_delta)} /> : null }
						<ValueCell>{roundWell(row.content.ca_personal_offer)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.ca_personal_offer_delta)} /> : null }
						<ValueCell>{roundWell(row.content.mb_personal_offer)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.mb_personal_offer_delta)} /> : null }
						<ValueCell>{roundWell(row.content.discount)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.discount_delta)} /> : null }
						<ValueCell>{roundWell(row.content.overbilling)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.overbilling_delta)} /> : null }
						<ValueCell>{roundWell(row.content.ddp)}</ValueCell>
						{ delta ? <DeltaCell value={roundWell(row.content.ddp_delta)} /> : null }
					</TableRow>
				))}

				{/* Total */}
				{data.length > 0 && (
					<TableRow className="total">
						<ValueCell>Total</ValueCell>
						{
							pareto ? <ValueCell>{data.length}</ValueCell> : null
						}
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_total, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_total, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_shipment, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_shipment, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_insurance, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_insurance, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.avg_shipments, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.nb_docs, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.nb_parcels, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_shipments, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						{ pareto ? null :
							<>
								<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_active_clients, 0))}</ValueCell>
								{ delta ? <ValueCell></ValueCell> : null }
							</>
						}
						{ pareto ? null :
							<>
								<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.total_created_accounts, 0))}</ValueCell>
							</>
						}
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ca_personal_offer, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.mb_personal_offer, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.discount, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.overbilling, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						<ValueCell>{roundWell(data.reduce((acc, row) => acc + row.content.ddp, 0))}</ValueCell>
						{ delta ? <ValueCell></ValueCell> : null }
						</TableRow>
				)}
			</TableBody>
		</Table>
		</div>
	)
}