import { useEffect, useState } from "react";
import StatisticsSellingsControls from "./StatisticsSellingsControls";
import StatisticsSellingsTable from "./StatisticsSellingsTable";
import PropTypes from 'prop-types';
import { Stack } from "@mui/material";
import { fetchSellingsData } from "./service/api";
import { monthsOptions } from "../fields/MonthsPeriod";
import { yearsOptions } from "../fields/YearsComparison";
import useToken from "../../login/useToken";

function StatisticsSellings() {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { token } = useToken();

	const [shipmentType, setShipmentType] = useState(0);
	const [monthsPeriod, setMonthsPeriod] = useState([monthsOptions[new Date().getMonth()]]);
	const [yearsComparison, setYearsComparison] = useState([yearsOptions[0]]);
	const [activity, setActivity] = useState(6);
	const [display, setDisplay] = useState(0);
	const [conFunnels, setConFunnels] = useState(-1);
	const [delta, setDelta] = useState(false);

	async function fetchData(signal) {
		const data = (await fetchSellingsData(signal, token, shipmentType, [...monthsPeriod].map(i => i.value), [...yearsComparison].map(i => i.value), activity, display, conFunnels)).data.monthsData;
		if(signal.aborted) return;
		setData(data);
		setLoading(false);
	}

	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			setLoading(true);
			fetchData(abortController.signal);
		})();  
		return () => {
			abortController.abort();
		};
	}, [shipmentType, monthsPeriod, yearsComparison,
		activity, display, conFunnels]);

	return (
		<>
			<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
				<StatisticsSellingsControls
					shipmentType={shipmentType}
					monthsPeriod={monthsPeriod}
					yearsComparison={yearsComparison}
					activity={activity}
					display={display}
					setShipmentType={setShipmentType}
					setMonthsPeriod={setMonthsPeriod}
					setYearsComparison={setYearsComparison}
					setActivity={setActivity}
					setDisplay={setDisplay}
					conFunnels={conFunnels}
					setConFunnels={setConFunnels}
					delta={delta}
					setDelta={setDelta}
				/>
			</Stack>
			{loading ? <div style={{textAlign:'center', marginTop:"50px"}}>Chargement...</div> : <StatisticsSellingsTable data={data} activity={activity} delta={delta} isPercent={display === 1 ? true : false} />}
		</>
	);
}

StatisticsSellings.propTypes = {
	data: PropTypes.array,
}

export default StatisticsSellings;