import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import ArchiveIcon from '@mui/icons-material/Archive';
import SvgIcon from '@mui/material/SvgIcon';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TrackingHeader from '../tracking/TrackingHeader';
import TrackingList from '../tracking/TrackingList';
import ArchiveTrackingController from '../archive/ArchiveTrackingController';
import StatusManagement from '../status/StatusManagement';
import TaskManagement from '../task/TaskManagement';
import Dashboard from '../dashboard/Dashboard';
import CustomerAccountController from '../customer-account/CustomerAccountController';
import CustomerAccountControllerDemo from '../customer-account-demo/CustomerAccountControllerDemo';
import ContactsController from '../contacts/ContactsController';
import useToken from '../login/useToken';
import GeneralSearch from './GeneralSearch';
import ShipmentsHead from '../shipments/ShipmentsHead';
import ShipmentsBody from '../shipments/ShipmentsBody';
import { URL_All } from '../common/Constants';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';
import { FrameTaskMngRow } from '../contexts/FrameTaskMngRow';
import { ShipmentsContext } from '../contexts/ShipmentsContext';
import { IssuesContext } from '../contexts/IssuesContext';
import { FrameTrackingContext } from '../contexts/FrameTrackingContext';
import { FrameArchiveContext } from '../contexts/FrameArchiveContext';
import { FrameChatContext } from '../contexts/FrameChatContext';
import { FrameAlertContext } from '../contexts/FrameAlertContext';
import mainLogo from '../static/images/logo_expedismart_white.svg';
import IssuesHeader from '../issues/IssuesHeader';
import IssuesBody from '../issues/IssuesBody';
import { getTaskCommentCount,
  getUsers,
  setIssuesSearchInput,
  setDrawerOpen,
  setRefresh,
  getTaskReminder,
  setCRTypes,
  setContactRequestCount,
  setTrackingsFilter,
  setCAGetCustomerById,
  setGoToCA,
  setCALoadAll,
  setChatUnreadShips,
  setPickupModify,
  getConversionFunnels,
} from '../actions';
import { getNewadminDB } from '../apis/fetch';
import { URL_NEWADMINDB } from '../apis/newadmindb';
import AlertTaskPopUp from '../alert-tasks/AlertTaskPopUp';
import ContactRequestHeader from '../contact-request/ContactRequestHeader';
import ContactRequestBody from '../contact-request/ContactRequestBody';
import crImg from '../static/images/crImg.png';
import agendaImg from '../static/images/agenda.png';
import { initialValues as trackingsInitialValues } from '../reducers/trackingsFilter';
import InvoicesHeader from '../invoices/InvoicesHeader';
import InvoicesBody from '../invoices/InvoicesBody';
import Overcharging from '../invoice-overcharging/Overcharging';
import Tax from '../invoice-tax/Tax';
import Invoicing from '../customer-account/Invoicing';
import { invoicesExpedismartPath,
  overchargingPath,
  taxPath,
  customerAccountPath,
  caInvoicingOtherPath,
  caInvoicingTranspPath,
} from './paths';
import SnackbarMessage from '../common/SnackbarMessage';
import ChatController from '../chat/ChatController';
import chatImg from '../static/images/chat.svg';
import { Circle } from '@mui/icons-material';
import StatisticsFinance from '../statistics/finance/StatisticsFinance';
import StatisticsCustomers from '../statistics/customers/StatisticsCustomers';
import StatisticsBilling from '../statistics/billing/StatisticsBilling';
import StatisticsTransporters from '../statistics/transporters/StatisticsTransporters';
import StatisticsInsurances from '../statistics/insurances/StatisticsInsurances';
import StatisticsIssues from '../statistics/issues/StatisticsIssues';
import StatisticsDestinations from '../statistics/destinations/StatisticsDestinations';
import StatisticsDashboard from '../statistics/dashboard/StatisticsDashboard';
import StatisticsSellings from '../statistics/sellings/StatisticsSellings';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © MyCargo Corp. '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Copyright2GIK() {
	return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © 2GIK '}
      {new Date().getFullYear()}
      {'.'}
      </Typography>
    </>
	);
}

const appBarHeight = 64;
const drawerWidth = 240;
// Frame designed to fit FullHD laptop
// (zoom 1.25, it means width is 1536px instead of 1920),
// so minWidth should be 1464px for good view quality
// const minWidthContainer = 1280;
const minWidthContainer = 1464;

const scrollbarThumb = '#122230';
const scrollbar = '#CFD8DC';

const useStyles = makeStyles()((theme) => { return {
  root: {
    flexGrow: 1,
    display: 'flex',
    '& .MuiToolbar-regular': {
      height: `${appBarHeight}px`,
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#122230',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    height: '100vh',

    '& > .MuiPaper-root': {
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        width: 0,
      },

      '&:hover': {
        '&::-webkit-scrollbar': {
          width: 11,
        },
  
        scrollbarWidth: 'thin',
        scrollbarColor: `${scrollbar} ${scrollbarThumb}`,
  
        '&::-webkit-scrollbar-track': {
          background: scrollbarThumb,
        },
  
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: scrollbar,
          borderRadius: 6,
          border: `3px solid ${scrollbarThumb}`,
        },
      },
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiListItem-button": {
      "& .MuiListItemIcon-root": {
        color: '#fff',
      },
      color: '#fff',
      "&:hover": {
        backgroundColor: '#1f394f',
      },
    },
    "& .MuiListSubheader-root": {
      color: 'white',
    },
    "& .MuiDivider-root": {
      borderColor: '#1f394f',
    },
    background: '#122230',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    "& .MuiListItem-button": {
      "& .MuiListItemIcon-root": {
        color: '#fff',
      },
      color: '#fff',
      "&:hover": {
        backgroundColor: '#1f394f',
      },
    },
    "& .MuiListSubheader-root": {
      color: 'white',
    },
    "& .MuiDivider-root": {
      borderColor: '#1f394f',
    },
    background: '#122230',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    width: `calc(100% - ${drawerWidth}px)`,
    overflowX: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    maxHeight: `calc(100vh - ${appBarHeight}px)`,
    overflow: 'auto',
    minWidth: minWidthContainer,
  },
  stickyHead: {
    position: 'sticky',
    top: theme.spacing(-1),
    zIndex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    background: '#F7F7F7',

    '&.overflowUnset': {
      overflow: 'unset',
    },
  },
  // paperGen: {
  //   padding: 0,
  //   paddingTop: theme.spacing(2),
  //   // overflowX: 'hidden',

  //   display: 'flex',
  //   overflow: 'auto',
  //   flexDirection: 'column',
  //   background: '#F7F7F7',
  // },
  paperStatisticsGeneral: {
    padding: 0,
    paddingTop: theme.spacing(2),
    overflowX: 'hidden',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    background: '#F7F7F7',
  },
  fixedHeight: {
    height: 240,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  selectedLeftMenu: {
    background: '#5b5d66',
  },
  textWhite: {
    color: '#fff',
  },
  user: {
    display: 'flex',
  },
  userDown: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  grow: {
    flexGrow: 1,
  },
    rootSnackbar: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
    },
    },
  noneTextTransform: {
    textTransform: "none",
    fontSize: theme.spacing(2),
  },
  crImg: {
    filter: 'contrast(2)',
  },
  rootGen: {
    background: '#F7F7F7',
  },
  table: {
    '& th, td': {
      padding: 0,
    },
  },
  chatImg: {
    filter: 'invert(1)',
    width: 27,
  },
  listSubheader: {
    backgroundColor: 'inherit',
  },
}});

function SearchMapIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M14,6.11L8,4V15.89L9,16.24V16.5C9,17.14 9.09,17.76 9.26,18.34L8,17.9L2.66,19.97L2.5,20A0.5,0.5 0 0,1 2,19.5V4.38C2,4.15 2.15,3.97 2.36,3.9L8,2L14,4.1L19.34,2H19.5A0.5,0.5 0 0,1 20,2.5V11.81C18.83,10.69 17.25,10 15.5,10C15,10 14.5,10.06 14,10.17V6.11Z" />
    </SvgIcon>
  );
}

function AccountDetails(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z" />
    </SvgIcon>
  );
}

export function logOut() {
  const locationPathname = window.location.pathname;
  const subdirectory = locationPathname.split('/')[1];

  localStorage.clear();
  sessionStorage.clear();
  window.location.href = `/${subdirectory}`;
  window.location.reload();
}

export async function getDataWithAuth(url, token) {
  if(!token) return logOut();

  const response = await fetch(url, {
      headers: {
          'authorization': `JWT ${token}`
      }
  })

  if (response.status === 403) {
    return logOut();
  }

  return response.json();
}

export function numberThousands(x) {
  if(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’");
  } else {
    return x;
  }
}

export function getMonthFrench(date) {
  let month = [];
  month[0] = "janvier";
  month[1] = "février";
  month[2] = "mars";
  month[3] = "avril";
  month[4] = "mai";
  month[5] = "juin";
  month[6] = "juillet";
  month[7] = "août";
  month[8] = "septembre";
  month[9] = "octobre";
  month[10] = "novembre";
  month[11] = "décembre";

  const d = new Date(date);
  const n = month[d.getMonth()];
  return n;
}

// Convert TimeStamp to 10 avril 2021, 19:00
export function dateTimeFrench(params) {
  let dateTime = '';
  dateTime = `${(new Date(params*1000)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params*1000)}
  ${(new Date(params*1000)).getFullYear().toString()}, ${(new Date(params*1000)).getHours().toString().padStart(2, '0')}:${(new Date(params*1000)).getMinutes().toString().padStart(2, '0')}`;

  return dateTime;
}

// Convert from any date (2021-04-16) to 16 avril 2021
export function convertDateToFrench(params) {
  let dateOnly = '';
  dateOnly = `${(new Date(params)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params)}
  ${(new Date(params)).getFullYear().toString()}`;

  return dateOnly;
}

// date 2021-01-17
export function getDateToday() {
  const yyyy = (new Date()).getFullYear();
  const mm = ((new Date()).getMonth() + 1).toString().padStart(2, '0');
  const dd = (new Date()).getDate().toString().padStart(2, '0');
  const dateOnly = `${yyyy}-${mm}-${dd}`;

  return dateOnly;
}


export function convertDateToMS(date) {
  const dateInMilliseconds = (new Date(date)).getTime();
  return dateInMilliseconds;
}

function Frame(props) {
  const { classes } = useStyles();
  const { token } = useToken();
  const { getTaskCommentCount,
    getUsers,
    setIssuesSearchInput,
    setDrawerOpen,
    drawerOpen,
    setRefresh,
    refresh,
    getTaskReminder,
    setCRTypes,
    contactRequestCount,
    setContactRequestCount,
    setTrackingsFilter,
    setCAGetCustomerById,
    caGetCustomerById,
    caGoTo,
    setGoToCA,
    setCALoadAll,
    invoicesBilling,
    caButtons,
    setChatUnreadShips,
    setPickupModify,
    getConversionFunnels,
  } = props;
  // const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openStatistics, setOpenStatistics] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let findQuery = useQuery();
  let query = findQuery.get("search");

  const [mySearch, setMySearch] = useState(query ? query : '');
  const [sortByDate, setSortByDate] = useState(2);
  
  const [isClickedCheckToday, setClickedCheckToday] = useState(false);
  const [isClickedHighlight, setClickedHighlight] = useState(false);
  const [isClickedUnread, setClickedUnread] = useState(false);
  const [undoneTaskCount, setUndoneTaskCount] = useState(0);
  const [newChatCount, setNewChatCount] = useState('0');
  const [users, setUsers] = useState([]);
  const [todayChecked, setTodayChecked] = useState([]);
  const [statusValue, setStatusValue] = useState({});
  const [statusObjectValues, setStatusObjectValues] = useState([]);
  const [highlightCount, setHighlightCount] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerListAll, setCustomerListAll] = useState([]);
  const [customerListLoading, setCustomerListLoading] = useState(false);
  const [customerListError, setCustomerListError] = useState(false);
  const [searchTerm, sestSearchTerm] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  
  let location2 = useLocation();
  const [currentPath, setCurrentPath] = useState(location2.pathname);
  const [stopSearchCustomers, setStopSearchCustomers] = useState(true);
  const [generalSearchSelect, setGeneralSearchSelect] = useState(null);
  const [filterStatusByID, setFilterStatusByID] = useState(0);
  //** Customer Account starts */
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerInfoLoading, setCustomerInfoLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerID, setCustomerID] = useState(-1);
  const [existCompanies, setExistCompanies] = useState([]);
  const [conversionFunnelID, setConversionFunnelID] = useState(-1);
  const [salesPeople, setSalesPeople] = useState([]);
  const [usersUserID, setUsersUserID] = useState(-2);
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [state, setState] = useState({
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false
  });
  const [selectedTrackingID, setSelectedTrackingID] = useState(null);
  const [tasksCustomer, setTasksCustomer] = useState([]);
  const [commentsCustomer, setCommentsCustomer] = useState([]);
  const [countTasksCustomer, setCountTasksCustomer] = useState({});
  //** Customer Account ends */
  //** Shipment starts */
  const [searchShipment, setSearchShipment] = useState('');
  // initial value of both dateFrom and dateTo is today, if you change initial value
  // then you should also change ShipmentsHead.js useEffect 'today'
  const [dateFrom, setDateFrom] = useState(getDateToday());
  const [dateTo, setDateTo] = useState(getDateToday());
  const [shipments, setShipments] = useState([]);
  // shipmentsCountHeader for header to show number of daily test
  const [shipmentsCountHeader, setShipmentsCountHeader] = useState(0);
  const [shipmentProfit, setShipmentProfit] = useState({});
  const [countShipments, setCountShipments] = useState(0);
  const [notFoundShipment, setNotFoundShipment] = useState(false);
  const [getShipmentLoading, setGetShipmentLoading] = useState(false);
  const [dateNotSelected, setDateNotSelected] = useState(false);
  const [fromDateBigger, setFromDateBigger] = useState(false);
  const [shipmentPage, setShipmentPage] = useState(0);
  const [noOffer, setNoOffer] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(false);
  const [okBtn, setOkBtn] = useState(false);
  const [errorPickup, setErrorPickup] = useState(false);
  const [fetchDone, setFetchDone] = useState(true);
  //** Shipment ends */

  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = (!users.length || !users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0]) ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;
  
  useEffect(() => {
    let interval;
    interval = setInterval(() => setRefresh(refresh + 1), 10000);
  
     return () => {
      clearInterval(interval);
    }
  }, [refresh, setRefresh]);

  // This useEffect ONLY first load page 
  useEffect(() => {
    getNewadminDB(URL_NEWADMINDB.Users)
      .then(response => setUsers(response.data))
      .catch(error => console.error(error));

    // Get Users in first load the page
    getUsers();

    // Get Contact Requset List on first load the page
    setCRTypes();

    // Get Conversion Funnels List on first load the page
    getConversionFunnels(token);
  }, [token]);

  useEffect(() => {
    getNewadminDB(URL_NEWADMINDB.TaskManageUserCount)
        .then(response => users.map(k => {
            if(k.username.toLowerCase() === username.toLowerCase() & response.data[k.id] !== undefined) {
              setUndoneTaskCount(response.data[k.id].nbr_task_open);
              setNewChatCount(response.data[k.id].nbr_chat_new);
              setChatUnreadShips(response.data[k.id].id_shipment_customer_unread.split(','));
            }
            return null;
        }))
        .catch(error => console.error(error))
  }, [users, setUndoneTaskCount, refresh,
  username, setNewChatCount, setChatUnreadShips]);

  useEffect(() => {
    const todaysDate = Math.trunc(new Date('"' + (new Date().getMonth() + 1) + ' ' + new Date().getDate() + ' ' + new Date().getFullYear() + '"').getTime() / 1000);

    getNewadminDB(URL_NEWADMINDB.getStatusTodayChecked)
      .then(response => setTodayChecked(Object.values(response.data).filter(r => (r.is_actif === 1 & r.checked_at >= todaysDate)).map(g => g.shipment_id)))
      .catch(error => console.error(error));

    // Get Shipment Tasks & Comments count - for Customer-Account
    getTaskCommentCount();
  }, [refresh, getTaskCommentCount]);

  useEffect(() => {
    getNewadminDB(`${URL_NEWADMINDB.getStatusshipments}?status_id=${filterStatusByID}`)
        .then(response => {
          setStatusValue(response.data);
          setStatusObjectValues(Object.values(response.data).filter(o => (o.is_actif === 0)).map(p => p.shipment_id));
          setHighlightCount(Object.values(response.data).filter(r => (r.highlighted === 1 & r.is_actif === 1)).map(g => g.shipment_id));
        })
        .catch(error => console.error(error))
  }, [refresh, filterStatusByID, setStatusValue, setStatusObjectValues, setHighlightCount]);

  // Get tasks for Reminder (Alert-tasks)
  useEffect(() => {
    if(currentUser) {
      getTaskReminder(currentUser);
    }
  }, [getTaskReminder, currentUser, refresh]);

  // Open or Collapse Statistics Menu
  useEffect(() => {
    if(currentPath === '/statistics-general' | currentPath === '/statistics-by-type-of-customer') {
      setOpenStatistics(true);
    }
  }, [currentPath]);

  useEffect(() => {
    setContactRequestCount();
  }, [setContactRequestCount, refresh]);

  const anchorRef = React.useRef(null);
  let history = useHistory();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleCollapseStatistics = () => {
    setOpenStatistics(!openStatistics);
  };

  const handleToggle = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen2(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen2(false);
    }
  }

  const prevOpen = React.useRef(open2);
  useEffect(() => {
    if (prevOpen.current === true && open2 === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open2;
  }, [open2]);

  useEffect(() => {
      return history.listen((location) => { 
        setCurrentPath(location.pathname);
        location.pathname === '/tracking' && handleAllBtn();
      })
  },[history]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      if(searchTerm) {
        searchCustomers(searchTerm);
      }
    }, 500);

    return () => clearTimeout(timerID);
  }, [searchTerm]);

  function searchCustomers(search) {
    setCustomerListLoading(true);
    setCustomerList([]);

    getDataWithAuth(`${URL_All.ContactList}/?q=${search}`, token)
      .then(json => {
        setCustomerList(json.filter(f => (f.users[0] !== undefined)));
        setCustomerListLoading(false);
      })
      .catch(error => {
        console.error(error);
        setCustomerListError(true);
      })
  }

  function goToUrl(url) {
    return history.push(url);
  }
  
  // All customers for task management. If task has no tracking number, then customer name.
  // All cusotmers for customer account Transfer client pop-up
  useEffect(() => {
    let mounted = true;
    setCustomerListAll([]);

    getDataWithAuth(URL_All.ContactList, token)
      .then(json => {
          if(mounted) {
              setCustomerListAll(json.filter(f => (f.users[0] !== undefined)));
          }
      })
      .catch(error => {
          if(mounted) {
              console.error(error);
          }
      })

    return () => mounted = false;
  }, [token, setCustomerListAll]);

  function handleAllBtn(){
  return [
    setTrackingsFilter(trackingsInitialValues),
    setClickedCheckToday(false),
    setClickedHighlight(false),
    setClickedUnread(false),
    ];
  }

  const handleClearAll = () => {
    clearAll();
  };
  
  //****************************************************
  //***** Customer Account Controller Starts

  // Get selected customer from CUSTOMERS by id 
  function getCustomerByID(params) {
    setCustomer({});
    setCustomerLoading(true);
    setShipmentDetails({});
    setUsersUserID(-2);
    setCALoadAll(false);

    getDataWithAuth(`${URL_All.ContactList}/${params}/`, token)
      .then(json => {
        setCustomer(json);
        setUsersUserID(json.users.length > 1 ? -1 : json.users[0].id);
        getCustomerShipmentByID(params, false);
        setCustomerID(params);
        setExistCompanies(json.adjustments.map(tr => tr.transporter));
        setState({
            ...state, checked1: json.invoice_payment_available,
            checked2: json.monthly_invoice, checked4: json.ddp_active
        });
        getSalesPeople();
        setConversionFunnelID(json.conversion_funnel ? json.conversion_funnel : -1);
        getTasksByCustomerID(params);
        getCommentsByCustomerID(params);
        setCustomerLoading(false);
      })
      .catch(error => {
        console.error(error);
        setCustomerLoading(false);
      })
  }

  // Get selected customer from SHIPMENTS by id
  function getCustomerShipmentByID(params, getAll) {
    setCustomerInfo({});
    setCustomerInfoLoading(true);

    // then new fetch
    getDataWithAuth(`${URL_All.CustomerInfo}/?id=${params}${getAll ? '' : '&count=10'}`, token)
      .then(json => {
        if(stopSearchCustomers === false) {
          setCustomerInfo(json);
          setCustomerInfoLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
        setCustomerInfoLoading(false);
      })
  }

  function clearAll() {
    setCustomer({});
    setCustomerInfo({});
    setShipmentDetails({});
    setCustomerID(-1);
    setSelectedTrackingID(null);
    setConversionFunnelID(-1);
    setSalesPeople([]);
    setTasksCustomer([]);
    setCommentsCustomer([]);
    setCountTasksCustomer({});
    setCustomerList([]);
    setStopSearchCustomers(true);
    setAutocompleteValue(null);
  }
  
  function getSalesPeople() {
    getDataWithAuth(URL_All.conversionfunnels, token)
      .then(json => setSalesPeople(json))
      .catch(error => console.error(error))
  }

  function editSalesPerson(id) {
    if(!token) return logOut();
  
    fetch(`${URL_All.ContactList}/${customerID}/`, {
      method: "PUT",
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'authorization': `JWT ${token}`
      },
      body: JSON.stringify({ id: customerID, conversion_funnel: id })
    })
      .then(response => {
        if (response.status === 403) {
          return logOut();
        }

        getCustomerByID(customerID);
      })
      .catch(error => console.error(error))
  }
    
  // Get tasks by customer id
  function getTasksByCustomerID(args) {
    setTasksCustomer([]);
    countTasksCustomerID(args);

    getNewadminDB(URL_NEWADMINDB.TaskManageDateNew)
      .then(response => setTasksCustomer(response.data.filter(f => (f.customer_id === args & f.is_task === 1 & f.task_status !== 3))))
      .catch(error => console.error(error));
  }
    
  // Get count tasks by customer id
  function countTasksCustomerID(args) {
    getNewadminDB(`${URL_NEWADMINDB.CustomerTaskCommentCount}?customer_id=${args}`)
      .then(response => setCountTasksCustomer(response.data))
      .catch(error => console.error(error));
  }
    
  // Get comments by customer id
  function getCommentsByCustomerID(args) {
    setCommentsCustomer([]);

    getNewadminDB(URL_NEWADMINDB.TaskManageDateNew)
      .then(response => setCommentsCustomer(response.data.filter(f => (f.customer_id === args & f.is_task === 0)).filter(tr => (tr.tracking_nbr === ''))))
      .catch(error => console.error(error));
  }  
    
  // When get request, then automaticly toggle => invoice_payment_available: true/false
  function editPaymentByInvoice(id) {
    getDataWithAuth(`${URL_All.customersviewset}/${id}/n_invoice/`, token)
      .then(json => setState({ ...state, checked1: json.invoice_payment_available }))
      .catch(err => console.error(err))
  }
  
  // When get request, then automaticly toggle => monthly_invoice: true/false
  function editMonthlyInvoice(id) {
    getDataWithAuth(`${URL_All.customersviewset}/${id}/n_monthlyinvoice/`, token)
      .then(json => setState({ ...state, checked2: json.monthly_invoice }))
      .catch(err => console.error(err))
  }
  
  // When get request, then automaticly toggle => ddp_active: true/false
  function editDDP(id) {
    getDataWithAuth(`${URL_All.customersviewset}/${id}/n_ddp/`, token)
      .then(json => setState({ ...state, checked4: json.ddp_active }))
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if(caButtons.invoiceCreate && caButtons.transport) {
      goToUrl(customerAccountPath + caInvoicingTranspPath);
    }
    if(caButtons.invoiceCreate && caButtons.other) {
      goToUrl(customerAccountPath + caInvoicingOtherPath);
    }
  }, [caButtons, currentPath]);

    useEffect(() => {
        !drawerOpen && setOpenStatistics(false);
    }, [drawerOpen]);


  //***** Customer Account Controller Ends
  //****************************************************

  
  //****************************************************
  //***** Go to Customer Account Starts 
  //***** From Tracking & Archive
  useEffect(() => {
    if(Object.keys(caGetCustomerById).length && caGoTo) {
      const autocompleteValue = {
        id: caGetCustomerById.id,
        is_company: caGetCustomerById.is_company,
        users: caGetCustomerById.users,
        company_name: caGetCustomerById.company_name
      };

      setCustomerList([autocompleteValue]);
      getCustomerByID(caGetCustomerById.id);
      setAutocompleteValue(autocompleteValue);
      goToUrl('/customer-account');
      setCAGetCustomerById({});
      setGoToCA(false);
    }
  }, [caGetCustomerById]);

  //***** Go to Customer Account Ends
  //***** From Tracking & Archive
  //****************************************************


  //****************************************************
  //***** Shipment Controller Starts

  useEffect(() => {
    if(currentPath === '/shipment' && (dateFrom.length > 0 && dateTo.length > 0) && (convertDateToMS(dateTo) >= convertDateToMS(dateFrom))) {
      setShipmentPage(0);
      setShipments([]);
      setShipmentsCountHeader(0);
      setShipmentProfit({});
      setCountShipments(0);
      setNotFoundShipment(false);
      setDateNotSelected(false);
      setFromDateBigger(false);
      setGetShipmentLoading(true);
      setFetchDone(false);

      let shipmentUrl = `${URL_All.ShipmentList}${noOffer ? 'no_quotes' : selectedOffer ? 'no_offer,quote' : errorPickup ? 'no_pickup,offer,order' : okBtn ? 'offer,pickup' : 'all'}&from=${dateFrom}&is_ddp=${'false'}&to=${dateTo}&type=${'all'}`;

      getDataWithAuth(shipmentUrl, token)
        .then(json => {
          if(json.results.length > 0) {
            setGetShipmentLoading(false);
            setFetchDone(true);
            setPickupModify({ shipId: 0, confirmNumber: '' });
            setShipments(json.results);
            setShipmentsCountHeader(json.count);
            setCountShipments(json.results.length);
            // percent_profit, total_profit, total_turnover
            setShipmentProfit({ percent_profit: json.percent_profit, total_profit: json.total_profit, total_turnover: json.total_turnover });
          } else {
            setGetShipmentLoading(false);
            setFetchDone(true);
            setNotFoundShipment(true);
          }
        })
        .catch(error => {
          setGetShipmentLoading(false);
          setFetchDone(true);
          console.error(error);
        })
    }
  },[currentPath, dateFrom, dateTo, token, noOffer, selectedOffer, errorPickup, okBtn]);

  function handleCountShipments(args) {
    setCountShipments(args);
  }

  //***** Shipment Controller Ends
  //****************************************************

  //****************************************************
  //***** All Invoices Pages: Invoices, Overcharging, Tax Starts 

  useEffect(() => {
    if(invoicesBilling.overcharging) {
      goToUrl(invoicesExpedismartPath + overchargingPath);
    }

    if(invoicesBilling.tax) {
      goToUrl(invoicesExpedismartPath + taxPath);
    }
  }, [invoicesBilling, currentPath]);

  //***** All Invoices Pages: Invoices, Overcharging, Tax Ends
  //****************************************************

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {currentPath === undefined || currentPath === '/' ? 'Dashboard'
            : currentPath === '/tracking' ? 'Tracking'
            : currentPath === '/contacts' ? 'Contacts'
            : currentPath === '/customer-account' ? 'Customer Account'
            : currentPath === '/customer-account-demo' ? 'Customer Account Demo'
            : currentPath === '/shipment' ? 'Shipment'
            : currentPath === '/archive' ? 'Archive'
            : currentPath === '/messaging' ? 'Messaging (Chat)'
            : currentPath === '/more' ? 'Some Info'
            : currentPath === '/status-management' ? 'Status Management'
            : currentPath === '/task-management' ? 'Task Management'
            : currentPath === '/issues' ? 'Issues'
            : currentPath === '/contact-request' ? 'Contact Request'
            : currentPath === '/statistics-general' ? 'Statistiques générales'
            : currentPath === '/statistics-by-type-of-customer' ? 'Statistics - By Type Of Customer'
            : currentPath === '/statistics-dashboard' ? 'Statistics - Dashboard'
            : currentPath === '/statistics-finance' ? 'Statistics - Finance'
			: currentPath === '/statistics-customers' ? 'Statistics - Customers'
			: currentPath === '/statistics-billing' ? 'Statistics - Billing'
			: currentPath === '/statistics-transporters' ? 'Statistics - Transporters'
			: currentPath === '/statistics-insurances' ? 'Statistics - Insurances'
			: currentPath === '/statistics-issues' ? 'Statistics - Issues'
			: currentPath === '/statistics-destinations' ? 'Statistics - Destinations'
			: currentPath === '/statistics-sellings' ? 'Statistics - Sellings'
            : currentPath === (invoicesExpedismartPath) ? 'Factures Expedismart'
            : currentPath === (invoicesExpedismartPath + overchargingPath) ? 'Facturation surfacturation'
            : currentPath === (invoicesExpedismartPath + taxPath) ? 'Facturation taxe'
            : 'MyCargo Corp.'}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          <GeneralSearch
            customerList={customerList}
            customerListError={customerListError}
            customerListLoading={customerListLoading}
            sestSearchTerm={sestSearchTerm}
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
            setCustomerList={setCustomerList}
            setStopSearchCustomers={setStopSearchCustomers}
            setGeneralSearchSelect={setGeneralSearchSelect}
            getCustomerByID={getCustomerByID}
            handleClearAll={handleClearAll}
          />
          </div>
          <div className={classes.grow} />
            <FrameAlertContext.Provider value={{ setStopSearchCustomers }}>
              <AlertTaskPopUp />
            </FrameAlertContext.Provider>
          <div className={classes.sectionDesktop}>
            <Button
              className={classes.noneTextTransform}
              edge="end"
              ref={anchorRef}
              aria-controls={open2 ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              {username}
            </Button>
            <Popper open={open2} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open2} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={() => logOut()}>Log Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img style={{ width: '70%' }} src={mainLogo} alt="MyCargo Corp." />
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon className={classes.textWhite} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem
            className={currentPath === '/' ? classes.selectedLeftMenu : null}
            button
              component={Link} to="/"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <Divider light />
            <ListItem
              className={currentPath === '/shipment' ? classes.selectedLeftMenu : null}
              button
              component={Link} to="/shipment"
            >
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Shipment" pl={8} />
            </ListItem>
            <ListItem
              className={currentPath === '/tracking' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/tracking"
            >
              <ListItemIcon>
                <SearchMapIcon />
              </ListItemIcon>
              <ListItemText primary="Tracking" />
            </ListItem>
            <ListItem
              className={currentPath === '/messaging' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/messaging"
            >
              <ListItemIcon>
                <Badge badgeContent={parseInt(newChatCount)} color="secondary">
                  <img src={chatImg} alt="Chat" className={classes.chatImg} />
              </Badge>
              </ListItemIcon>
              <ListItemText primary="Messaging (Chat)" pl={8} />
            </ListItem>
            <ListItem
              className={currentPath === '/issues' ? classes.selectedLeftMenu : null}
              button
              component={Link} to="/issues"
              onClick={() => setIssuesSearchInput('')}
            >
              <ListItemIcon>
                <ErrorOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Issues" pl={8} />
            </ListItem>
            <ListItem
              className={currentPath === '/archive' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/archive"
            >
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>
              <ListItemText primary="Archive" pl={8} />
            </ListItem>
            <ListItem
              className={currentPath === '/customer-account' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/customer-account"
            >
              <ListItemIcon>
                <AccountDetails />
              </ListItemIcon>
              <ListItemText primary="Customer Account" />
            </ListItem>
          </div>
        </List>
        <List><div>
          <Divider light />
          <ListSubheader className={classes.listSubheader} inset>More</ListSubheader>
          <ListItem
            className={currentPath === '/task-management' ? classes.selectedLeftMenu : null}
            button
            component={Link} to="/task-management"
          >
            <ListItemIcon>
              <Badge badgeContent={undoneTaskCount.toString() !== '0' ? undoneTaskCount : null} color="secondary">
                <AssignmentTurnedInIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Task Management" />
          </ListItem>
          <ListItem
            className={currentPath === '/status-management' ? classes.selectedLeftMenu : null}
            button
            component={Link} to="/status-management"
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Status Management" />
          </ListItem>
        </div></List>
        <List><div>
          <Divider light />
          <ListSubheader className={classes.listSubheader} inset>Demo</ListSubheader>
            <ListItem
              className={currentPath === invoicesExpedismartPath ? classes.selectedLeftMenu : null}
              button
              component={Link} to={invoicesExpedismartPath}
            >
              <ListItemIcon>
                <img src={agendaImg} alt="Invoices" className={classes.crImg} />
              </ListItemIcon>
              <ListItemText primary="Factures" pl={8} />
            </ListItem>
            

            <ListItem
              className={currentPath === '/contact-request' ? classes.selectedLeftMenu : null}
              button
              component={Link} to="/contact-request"
            >
              <ListItemIcon>
                <Badge badgeContent={contactRequestCount ? contactRequestCount.total : null} color="secondary">
                <img src={crImg} alt="cr" className={classes.crImg} />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Contact Request" pl={8} />
            </ListItem>


            <ListItem button onClick={handleCollapseStatistics}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Statistics" pl={8} />
              {openStatistics ? <ExpandLess /> : <ExpandMore />}
            </ListItem>


            <Collapse in={openStatistics} timeout="auto" unmountOnExit>
			<ListItem
                className={currentPath === '/statistics-dashboard' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-dashboard"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Dashboard" pl={8} />
              </ListItem>
			  <ListItem
                className={currentPath === '/statistics-finance' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-finance"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Finance" pl={8} />
              </ListItem>
			        <ListItem
                className={currentPath === '/statistics-customers' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-customers"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Customers" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-billing' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-billing"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Billing" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-transporters' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-transporters"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Transporters" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-insurances' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-insurances"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Insurances" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-issues' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-issues"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Issues" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-destinations' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-destinations"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Destinations" pl={8} />
              </ListItem>
              <ListItem
                className={currentPath === '/statistics-sellings' ? classes.selectedLeftMenu : null}
                button
                component={Link} to="/statistics-sellings"
              >
                <ListItemIcon>
                    {drawerOpen ? null : <Circle />}
                </ListItemIcon>
                <ListItemText primary="Sellings" pl={8} />
              </ListItem>

            </Collapse>

            <ListItem
              className={currentPath === '/contacts' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/contacts"
            >
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Contacts" />
            </ListItem>
            <ListItem
              className={currentPath === '/customer-account-demo' ? classes.selectedLeftMenu : null}
              button
              onClick={() => setMySearch('')}
              component={Link} to="/customer-account-demo"
            >
              <ListItemIcon>
                <AccountDetails />
              </ListItemIcon>
              <ListItemText primary="Customer A. Demo" />
            </ListItem>
        </div></List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
            <Switch>
              <Route path="/tracking">
                <FrameTrackingContext.Provider value={{ getCustomerByID,
                  setAutocompleteValue,
                  setCustomerList,
                  setStopSearchCustomers,
                }}>                
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                      <Paper className={classes.paper}>
                        <TrackingHeader
                          searchInput={mySearch}
                          setMySearch={setMySearch}
                          setSortByDate={setSortByDate}
                          handleAllBtn={handleAllBtn}
                          isClickedCheckToday={isClickedCheckToday}
                          isClickedHighlight={isClickedHighlight}
                          isClickedUnread={isClickedUnread}
                          filterStatusByID={filterStatusByID}
                          setFilterStatusByID={setFilterStatusByID}
                          todayCheckedCount={todayChecked.length}
                          setClickedCheckToday={setClickedCheckToday}
                          setClickedHighlight={setClickedHighlight}
                          setClickedUnread={setClickedUnread}
                          highlightCount={highlightCount.length}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TrackingList
                        input={mySearch}
                        sortByDate={sortByDate}
                        isClickedCheckToday={isClickedCheckToday}
                        isClickedHighlight={isClickedHighlight}
                        isClickedUnread={isClickedUnread}
                        statusObjectValues={statusObjectValues}
                        highlightCount={highlightCount}
                        statusValue={statusValue}
                        todayChecked={todayChecked}
                        setStatusValue={setStatusValue}
                        setStatusObjectValues={setStatusObjectValues}
                        setHighlightCount={setHighlightCount}
                      />
                    </Grid>
                  </Grid>
                </FrameTrackingContext.Provider>
              </Route>
              <Route path="/contacts">
                <ContactsController />
              </Route>
              <Route exact path="/customer-account">
                <CustomerAccountContext.Provider value={{ statusObjectValues,
                    users,
                    customerList,
                    customerListAll,
                    customerListLoading,
                    customerListError,
                    autocompleteValue,
                    stopSearchCustomers,
                    generalSearchSelect,
                    customer,
                    salesPeople,
                    conversionFunnelID,
                    existCompanies,
                    customerInfo,
                    customerInfoLoading,
                    customerLoading,
                    customerID,
                    usersUserID,
                    shipmentDetails,
                    state,
                    selectedTrackingID,
                    tasksCustomer,
                    countTasksCustomer,
                    commentsCustomer,
                    statusValue,
                    clearAll,
                    handleClearAll,
                    setSelectedTrackingID,
                    getCommentsByCustomerID,
                    getTasksByCustomerID,
                    setState,
                    setShipmentDetails,
                    setUsersUserID,
                    getCustomerByID,
                    editSalesPerson,
                    setAutocompleteValue,
                    setMySearch,
                    sestSearchTerm,
                    setCustomerList,
                    setStopSearchCustomers,
                    editPaymentByInvoice,
                    editMonthlyInvoice,
                    editDDP,
                    getCustomerShipmentByID,
                  }}>
                  <CustomerAccountController />
                </CustomerAccountContext.Provider>
              </Route>
              <Route path="/customer-account-demo">
                <CustomerAccountControllerDemo />
              </Route>
              <Route path="/shipment">
                <Grid container space={2}>
                  <ShipmentsContext.Provider value={{
                    searchShipment,
                    dateFrom,
                    dateTo,
                    countShipments,
                    notFoundShipment,
                    getShipmentLoading,
                    dateNotSelected,
                    fromDateBigger,
                    noOffer,
                    selectedOffer,
                    errorPickup,
                    okBtn,
                    fetchDone,
                    shipmentsCountHeader,
                    setNoOffer,
                    setSelectedOffer,
                    setErrorPickup,
                    setOkBtn,
                    setShipments,
                    setShipmentsCountHeader,
                    setShipmentProfit,
                    handleCountShipments,
                    setFromDateBigger,
                    setDateNotSelected,
                    setDateFrom,
                    setDateTo,
                    setSearchShipment,
                    setShipmentPage,
                    setStopSearchCustomers,
                  }}>
                    <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                      <Paper className={classes.paper}>
                        <ShipmentsHead shipmentProfit={shipmentProfit} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Paper>
                        <ShipmentsBody
                          shipments={shipments}
                          page={shipmentPage}
                          setPage={setShipmentPage}
                        />
                      </Paper>
                    </Grid>
                  </ShipmentsContext.Provider>
                </Grid>
              </Route>
              <Route path="/contact-request">
                <Grid container space={2}>
                  <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                    <Paper className={classes.paper}>
                      <ContactRequestHeader />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                      <ContactRequestBody />
                    </Paper>
                  </Grid>
                </Grid>
              </Route>
              <Route path="/archive">
                <FrameArchiveContext.Provider value={{ getCustomerByID,
                  setAutocompleteValue,
                  setCustomerList,
                  setStopSearchCustomers,
                }}>
                  <ArchiveTrackingController
                    searchInput={mySearch}
                    statusObjectValues={statusObjectValues}
                  />
                </FrameArchiveContext.Provider>
              </Route>
              <Route path="/task-management">
                <FrameTaskMngRow.Provider value={{ setMySearch, customerListAll }}>
                  <TaskManagement
                    statusValue={statusValue}
                    setUndoneTaskCount={setUndoneTaskCount}
                  />
                </FrameTaskMngRow.Provider>
              </Route>
              <Route path="/status-management">
                <StatusManagement users={users} />
              </Route>
              <Route path="/issues">
                <IssuesContext.Provider value={{ statusValue }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                      <Paper className={classes.paper}>
                        <IssuesHeader />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <IssuesBody />
                    </Grid>
                  </Grid>
                </IssuesContext.Provider>
              </Route>
              <Route path="/statistics-dashboard">
                <StatisticsDashboard />
              </Route>
              <Route path="/statistics-finance">
                <StatisticsFinance />
              </Route>
              <Route path="/statistics-customers">
                <StatisticsCustomers />
              </Route>
              <Route path="/statistics-billing">
                <StatisticsBilling />
              </Route>
              <Route path="/statistics-transporters">
                <StatisticsTransporters />
              </Route>
              <Route path="/statistics-insurances">
                <StatisticsInsurances />
              </Route>
              <Route path="/statistics-issues">
                <StatisticsIssues />
              </Route>
              <Route path="/statistics-destinations">
                <StatisticsDestinations />
              </Route>
              <Route path="/statistics-sellings">
                <StatisticsSellings />
              </Route>
              <Route exact path={invoicesExpedismartPath}>
                <Grid container space={2}>
                  <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                    <Paper className={`${classes.paper} overflowUnset`}>
                      <InvoicesHeader />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                      <InvoicesBody />
                    </Paper>
                  </Grid>
                </Grid>
              </Route>
              <Route exact path={invoicesExpedismartPath + overchargingPath}>
                <Overcharging />
              </Route>
              <Route exact path={invoicesExpedismartPath + taxPath}>
                <Tax />
              </Route>
              <Route exact path={customerAccountPath + caInvoicingTranspPath}>
                <CustomerAccountContext.Provider value={{ 
                    customer,
                    usersUserID,
                  }}>
                  <Invoicing />
                </CustomerAccountContext.Provider>
              </Route>
              <Route exact path={customerAccountPath + caInvoicingOtherPath}>
                <CustomerAccountContext.Provider value={{ 
                    customer,
                    usersUserID,
                  }}>
                  <Invoicing />
                </CustomerAccountContext.Provider>
              </Route>
              <Route path="/messaging">
                <FrameChatContext.Provider value={{ getCustomerByID,
                  setAutocompleteValue,
                  setCustomerList,
                  setStopSearchCustomers,
                }}>
                  <ChatController
                    searchInput={mySearch}
                    statusObjectValues={statusObjectValues}
                  />
                </FrameChatContext.Provider>
              </Route>
              <Route path="/">
                <Dashboard />
              </Route>
            </Switch>
          <Box pt={4}>
			{currentPath === '/statistics-dashboard'
			|| currentPath === '/statistics-finance'
			|| currentPath === '/statistics-customers'
			|| currentPath === '/statistics-billing'
			|| currentPath === '/statistics-transporters'
			|| currentPath === '/statistics-insurances'
			|| currentPath === '/statistics-issues'
			|| currentPath === '/statistics-destinations'
			|| currentPath === '/statistics-sellings'
			|| null}
          </Box>
        </Container>
      </main>
      <SnackbarMessage />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    drawerOpen: state.drawerOpen,
    refresh: state.refresh,
    contactRequestCount: state.contactRequestCount,
    caGetCustomerById: state.caGetCustomerById,
    caGoTo: state.caGoTo,
    invoicesBilling: state.invoicesBilling,
    caButtons: state.caButtons,
  };
};

export default connect(mapStateToProps, {
  setDrawerOpen,
  getTaskCommentCount,
  getUsers,
  setIssuesSearchInput,
  setRefresh,
  getTaskReminder,
  setCRTypes,
  setContactRequestCount,
  setTrackingsFilter,
  setCAGetCustomerById,
  setGoToCA,
  setCALoadAll,
  setChatUnreadShips,
  setPickupModify,
  getConversionFunnels,
})(Frame);
