import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Grid from '@mui/material/Grid';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BurgerIcon from './BurgerIcon';
import PopUpPackage from './PopUpPackage';
import PopUpInfo from './PopUpInfo';
import AdditionalAddressInfo from './AdditionalAddressInfo';
import GenerateInvoice from './GenerateInvoice';
import PopUpAddress from '../tracking/PopUpAddress';
import { ShipmentsContext } from '../contexts/ShipmentsContext';
import upsLogo from '../static/images/logo_UPS.png';
import tntLogo from '../static/images/logo_TNT.png';
import fedexLogo from '../static/images/logo_FedEx.png';
import springLogo from '../static/images/logo_SPRING.png';
import aramexLogo from '../static/images/logo_ARAMEX.png';
import transnatLogo from '../static/images/logo_transnat.png';
import fretcargoLogo from '../static/images/logo_fretcargo.png';
import postFinancePng from '../static/images/pf_icon.png';
import twintPng from '../static/images/twint_icon.png';
import { convertDateToFrench, getDataWithAuth, numberThousands } from '../common/Frame';
import useToken from '../login/useToken';
import { URL_All, urlExpedismart } from '../common/Constants';
import { getCustomerByIDAction,
    setGoToCA,
} from '../actions';


const useStyles = makeStyles()((theme) => { return {
    root: {
        background: '#F7F7F7',
        // minHeight: '100vh',
    },
    tableHead:{
        background: '#727272',
        '& th': {
            textAlign: 'center',
            color: '#fff',
            fontWeight: '700',
        },
    },
    differentRows: {
        overflowWrap: 'anywhere',
        '& td': {
            textAlign: 'center',
        },
        '& .none': {
            backgroundColor: 'inherit',
        },
        '& .no_quotes': {
            background: '#ff8282',
        },
        '& .ok': {
            background: '#c5fac5',
        },
        '& .tracked_by_recipient': {
            background: '#ffe56b',
        },
        '& .discounted': {
            background: '#c6d5a3',
        },
        '& .pickup_error': {
            background: '#87dcea',
        },
        '& .payment_not_done': {
            background: '#e3b7e3',
        },
        '& .quote_selected': {
            background: '#beb9b9',
        },
        '& .greenIcon': {
            color: 'green',
        },
        '& .checkCircleIcon': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
    },
    infoShipmentColumn: {
        textAlign: 'left !important',
    },
    loadingOrNotFound: {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '2.5rem',
      color: '#9e9b9b',
      height: '60vh',
    },
    borderBottomRow: {
        '& td': {
            paddingBottom: theme.spacing(0),
            borderBottom: 'none',
        },
    },
    tableRowAdditional: {
        '& td': {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(.25),
            borderBottom: 'groove',
        },
    },
    packageIcon: {
      transform: 'scaleX(-1)', // flip horizontal
    },
    displayNone: {
        display: 'none',
    },
    tableCellUsername: {
        borderTop: 'none',
    },
    username: {
        textAlign: 'left',
        '& a': {
            textDecoration: 'none',
        },
    },
    carrierLogo: {
        maxWidth: '80%',
    },
    carrierLogoTransportInfo: {
        maxHeight: '24px',
    },
    doneAllIconGreen: {
      color: 'green',
    },
    doneAllIconGrey: {
      color: grey[700],
    },
    doneAllIconBlack: {
      color: grey[900],
    },
    arrowDropIconButton: {
        padding: '4px 12px',
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
        },
    },
    // arrowDropIcon: {
    //     color: "secondary",
    //     width: "4ch",
    //     fontSize: "2rem",
    // },
    transportInfo: {
        "& .MuiTableCell-root": {
          borderBottom: "1px solid #a9a1a1",
        },
        backgroundColor: '#cbefd5',
    },
    transportInfoHead: {
        '& th': {
            textAlign: 'center',
        },
        '& .floatLeft': {
            textAlign: 'start',
        },
    },
    transportInfoBody: {
        '& td': {
            textAlign: 'center',
            // borderBottom: 'none',
        },
        '& .floatLeft': {
            textAlign: 'start',
        },
    },
    error: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '1.5%',
    },
    gridCustomerAccount: {
        display: 'flex',
        padding: theme.spacing(1),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hrefCustomerAccount: {
        color: '#616161',
        padding: theme.spacing(.5),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '50%',
        },
    },
    noUser: {
        padding: theme.spacing(.5),
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    iconAtSameLine: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paymentMethod: {
      padding: theme.spacing(0.5),
    },
    bugerIconGrid: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    quoteServiceText: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(.5),
    },
    goToCAButton: {
      textTransform: 'none',
    },
}});

const useStyles2 = makeStyles()((theme) => { return {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}});

export function GoToCustomerAccount(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M19,21V19H15V17H19V15L22,18L19,21M10,4A4,4 0 0,1 14,8A4,4 0 0,1 10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,14C11.15,14 12.25,14.12 13.24,14.34C12.46,15.35 12,16.62 12,18C12,18.7 12.12,19.37 12.34,20H2V18C2,15.79 5.58,14 10,14Z" />
      </SvgIcon>
    );
}

function InvoiceIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M461 1679 c-83 -42 -127 -135 -138 -291 l-6 -88 92 0 91 0 0 -466 c0
                -412 2 -470 16 -501 32 -66 7 -64 582 -61 l520 3 39 31 c55 45 92 123 100 212
                l6 72 -91 0 -91 0 -3 498 c-3 476 -4 498 -23 531 -11 19 -33 43 -48 55 -28 21
                -38 21 -515 24 -482 2 -487 2 -531 -19z m995 -77 c32 -21 34 -52 34 -531 l0
                -481 -404 0 -403 0 -6 -80 c-6 -89 -30 -145 -63 -145 -19 0 -19 14 -24 600 -4
                463 -8 605 -18 623 l-13 22 443 0 c244 0 448 -4 454 -8z m-956 -144 l0 -68
                -45 0 c-36 0 -45 3 -45 18 0 33 27 120 45 149 l19 28 12 -30 c7 -17 13 -60 14
                -97z m1160 -978 c0 -30 -26 -75 -56 -99 -26 -21 -36 -21 -446 -21 -396 0 -420
                1 -413 18 5 14 25 107 25 118 0 2 200 4 445 4 442 0 445 0 445 -20z"/>
                <path d="M820 1442 c0 -22 -7 -32 -34 -45 -26 -12 -38 -26 -47 -56 -20 -66 -1
                -94 87 -136 41 -20 74 -40 74 -45 0 -22 -48 -29 -100 -16 l-51 13 -15 -35
                c-16 -39 -11 -47 36 -58 47 -11 50 -13 50 -44 0 -30 1 -31 43 -28 39 3 42 5
                45 33 2 23 11 34 34 44 36 16 62 70 53 111 -8 37 -49 73 -113 100 -70 30 -62
                45 21 44 l65 -2 7 35 c7 39 -4 53 -42 53 -19 0 -23 6 -23 30 0 29 -2 30 -45
                30 -43 0 -45 -1 -45 -28z"/>
                <path d="M1117 1413 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
                0 c-74 0 -137 -3 -140 -7z"/>
                <path d="M1112 1178 l3 -43 138 -3 137 -3 0 46 0 45 -141 0 -140 0 3 -42z"/>
                <path d="M1117 1023 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
                0 c-74 0 -137 -3 -140 -7z"/>
                <path d="M732 778 l3 -43 330 0 330 0 3 43 3 42 -336 0 -336 0 3 -42z"/>
            </g>
        </SvgIcon>
    );
}

function CreditCardIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M1343 1730 c-447 -156 -1252 -456 -1270 -473 -49 -44 -45 -60 97
            -455 73 -202 137 -371 142 -377 4 -5 8 183 8 418 0 453 1 462 49 494 24 17 76
            18 667 23 l641 5 -64 175 c-35 96 -72 183 -81 193 -39 38 -73 38 -189 -3z"/>
            <path d="M397 1299 c-15 -11 -31 -37 -37 -57 -8 -25 -10 -186 -8 -490 l3 -454
            32 -28 c20 -18 48 -31 75 -35 24 -3 354 -5 733 -3 640 3 692 4 716 21 14 9 31
            29 37 43 9 18 12 160 12 490 l0 466 -34 34 -34 34 -735 0 c-726 0 -735 0 -760
            -21z m1491 -51 c7 -7 12 -34 12 -60 l0 -48 -745 0 -745 0 0 41 c0 22 5 49 10
            60 10 19 29 19 733 19 549 0 726 -3 735 -12z m12 -637 c0 -288 0 -290 -22
            -305 -20 -14 -105 -16 -723 -16 -618 0 -703 2 -723 16 -22 15 -22 17 -22 305
            l0 289 745 0 745 0 0 -289z"/>
            </g>
        </SvgIcon>
    );
}

const errorConfig = {
    color: 'red',
    messageLength: 40,
    header: 'Erreur de description'
};

const additionalPickUpConfig = {
    color: '#3a3c47', // 0008ff
    messageLength: 30,
    header: 'Additional Pickup Info'
};

export const grossMargin = (a, b) => {
    if(isNaN(a) || isNaN(b)) return '';
    const c = a - b;
    return numberThousands(c.toFixed(2));
};

function Row(props) {
    const { classes } = useStyles();
    const { token } = useToken();
    const { shipmentOne,
        getCustomerByIDAction,
        setGoToCA,
        caGoTo,
        pickupModify,
    } = props;
    const { setStopSearchCustomers } = useContext(ShipmentsContext);
    const [shipment, setShipment] = useState(shipmentOne);
    const [open, setOpen] = useState(false);
    const [transportInfo, setTransportInfo] = useState([]);

    // const tempError = 'Some address errors. At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum.';

    // const tempAddInfo = 'Outer door code is 5566. On the second floor. At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum.';

    function getTransportInfo(id) {
        getDataWithAuth(`${URL_All.apiShipment}/${id}/quotes/`, token)
            .then(json => setTransportInfo(json))
            .catch(err => console.error(err))
    }

    function discount() {
        if(shipment.ikompar_invoice) {
            if(shipment.ikompar_invoice.discount_amount !== '0.00') {
                return <span>DISCOUNT: {shipment.ikompar_invoice.discount_amount}</span>;
            }
        }
    }

    let transporter_amount = null;
    let shipment_amount = null;
    let insurance_amount = null;
    let vat_amount = null;
    let total_amount = null;
    
    if(shipment.ikompar_invoice) {
      if(shipment.ikompar_invoice.transporter_amount) {
        transporter_amount = Number.parseFloat(shipment.ikompar_invoice.transporter_amount).toFixed(2);
      }
      if(shipment.ikompar_invoice.shipment_amount) {
        shipment_amount = Number.parseFloat(shipment.ikompar_invoice.shipment_amount).toFixed(2);
      }
      if(shipment.ikompar_invoice.insurance_amount) {
        insurance_amount = Number.parseFloat(shipment.ikompar_invoice.insurance_amount).toFixed(2);
      }
      if(shipment.ikompar_invoice.vat_amount) {
        vat_amount = Number.parseFloat(shipment.ikompar_invoice.vat_amount).toFixed(2);
      }
      if(shipment.ikompar_invoice.total_amount) {
        total_amount = Number.parseFloat(shipment.ikompar_invoice.total_amount).toFixed(2);
      }
    }
    
    const paymentMethodM = ((transporter_amount && shipment_amount) ? Number.parseFloat((1 - transporter_amount / shipment_amount) * 100).toFixed(2) : null);

    const paymentMethodAndInvoice = (!transporter_amount && !shipment_amount && !insurance_amount && !vat_amount && !total_amount) ? null : (
        <Grid item xs={12} className="paymentMethod">
            <span className={classes.iconAtSameLine}>
                {shipment.ikompar_invoice !== null && parseFloat(shipment.ikompar_invoice.number.split("-").join(''))}&nbsp;
                <Tooltip
                    className={classes.paymentMethod}
                    title={<div>
                        Transporter CHF: {transporter_amount !== 'NaN' && transporter_amount}
                        <br />Shipment CHF: {shipment_amount !== 'NaN' && shipment_amount}
                        <br />GM (%): {paymentMethodM !== 'Infinity' && paymentMethodM}
                        <br />Insurance CHF: {insurance_amount !== 'NaN' && insurance_amount}
                        <br />Vat CHF: {vat_amount !== 'NaN' && vat_amount}
                        <br />Total CHF: {total_amount !== 'NaN' && total_amount}
                        </div>}
                    placement="left-end"
                >
                <IconButton size="large">
                    {(shipment.ikompar_invoice === null) ? null : (shipment.ikompar_invoice.payment_method === 'CreditCard') ? <CreditCardIcon /> : (shipment.ikompar_invoice.payment_method === 'Invoice') ? <InvoiceIcon />
                      : ((shipment.ikompar_invoice.payment_method === 'Postfinance card') || (shipment.ikompar_invoice.payment_method === 'Postfinance e-finance') || (shipment.ikompar_invoice.payment_method === 'PF Pay')) ? <Avatar
                      variant="square"
                      alt={!shipment.ikompar_invoice ? 'Icon' : shipment.ikompar_invoice.payment_method}
                      src={postFinancePng}
                        /> : (shipment.ikompar_invoice.payment_method === 'Twint') ? <Avatar
                        variant="square"
                        alt={!shipment.ikompar_invoice ? 'Icon' : shipment.ikompar_invoice.payment_method}
                        src={twintPng}
                    /> : null}
                </IconButton>
                </Tooltip>
            </span>
        </Grid>
    );

    const handleGoToCA = () => {
        setGoToCA(true);
        setStopSearchCustomers(false);
        getCustomerByIDAction(token, shipment.customer_id);
    };
    
    const goToCustomerAccountButton = (
        <Button
            disabled={!shipment.user || caGoTo || !shipment.customer_id}
            className={classes.goToCAButton}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleGoToCA}
        >
            {shipment.user}
        </Button>
    );

    const pickupAddressInfo = {
        type: 'Pickup',
        street_number: shipment.pickup_street_number,
        street_line_1: shipment.pickup_street_line_1,
        street_line_2: shipment.pickup_street_line_2,
        zip_code: shipment.pickup_zip_code,
        city: shipment.pickup_city,
        state: shipment.pickup_state,
        country_name: shipment.pickup_country_name,
    full_address: shipment.pickup_address,
    };
    
      const deliveryAddressInfo = {
        type: 'Delivery',
        street_number: shipment.delivery_street_number,
        street_line_1: shipment.delivery_street_line_1,
        street_line_2: shipment.delivery_street_line_2,
        zip_code: shipment.delivery_zip_code,
        city: shipment.delivery_city,
        state: shipment.delivery_state,
        country_name: shipment.delivery_country_name,
    full_address: shipment.delivery_address,
    };

    return (
        <React.Fragment>
            <TableRow className={`${classes.borderBottomRow} ${shipment.admin_status}`}>
                <TableCell className={classes.infoShipmentColumn}>
                    ID:{shipment.id}<br />
                    N suivi:<br />
                    <span>{shipment.ikompar_invoice && shipment.ikompar_invoice.airwaybill.replace(/<br\/>/g, "\n")}</span><br />
                    Enlèvement:<br />
                    {(pickupModify.shipId === shipment.id && pickupModify.confirmNumber.length) ? pickupModify.confirmNumber : shipment.pickup_request.confirmation_number ? shipment.pickup_request.confirmation_number : ''}
                </TableCell>
                <TableCell>
                    {shipment.creation && convertDateToFrench(shipment.creation)}<br />
                    {shipment.creation && `(${shipment.creation.slice(11, 16)})`}
                </TableCell>
                <TableCell>
                    {(pickupModify.shipId === shipment.id && pickupModify.pickup_date.length) ? convertDateToFrench(pickupModify.pickup_date) 
                    : shipment.pickup_date ? convertDateToFrench(shipment.pickup_date) : ''}
                    <br />
                    {(pickupModify.shipId === shipment.id && pickupModify.ready_time && pickupModify.close_time) ? (`${pickupModify.ready_time.length && pickupModify.ready_time.slice(0, 5)} - ${pickupModify.close_time.length && pickupModify.close_time.slice(0, 5)}`)
                    : (shipment.pickup_request.ready_time && shipment.pickup_request.close_time) ? (`${shipment.pickup_request.ready_time.length && shipment.pickup_request.ready_time.slice(0, 5)} - ${shipment.pickup_request.close_time.length && shipment.pickup_request.close_time.slice(0, 5)}`) : null}
                </TableCell>
                <TableCell>
                    <span><b>{shipment.pickup_contact && shipment.pickup_contact}</b></span><br />
                    <span className={classes.contactNames}><b><i>{shipment.pickup_contact_name && shipment.pickup_contact_name}</i></b></span><br />
                    <PopUpAddress data={pickupAddressInfo} />
                    {(shipment.pickup_request.additional_info || shipment.pickup_request.floor_number || shipment.pickup_request.security_code || shipment.shipment_customer_reference) ? <AdditionalAddressInfo shipment={shipment} config={additionalPickUpConfig} /> : null}
                    {shipment.pickup_phone && shipment.pickup_phone}<br />
                    <span className={shipment.pickup_email && (shipment.pickup_email.length < 34) && classes.noWrap}>{shipment.pickup_email && shipment.pickup_email}</span><br />
                </TableCell>
                <TableCell>
                    <span><b>{shipment.delivery_contact && shipment.delivery_contact}</b></span><br />
                    <span className={classes.contactNames}><b><i>{shipment.delivery_contact_name && shipment.delivery_contact_name}</i></b></span><br />
                    <PopUpAddress data={deliveryAddressInfo} />
                    {shipment.delivery_phone && shipment.delivery_phone}<br />
                    <span className={shipment.delivery_email && (shipment.delivery_email.length < 34) && classes.noWrap}>{shipment.delivery_email && shipment.delivery_email}</span><br />
                </TableCell>
                <TableCell>
                    <PopUpPackage shipment={shipment} />

                    {shipment.package_group && shipment.package_group.packages[0] && <span>{Math.trunc(shipment.package_group.packages[0].length)}x{Math.trunc(shipment.package_group.packages[0].width)}x{Math.trunc(shipment.package_group.packages[0].height)}cm<br /></span>}

                    {shipment.package_group && `${shipment.package_group.total_weight}kg`}
                </TableCell>
                <TableCell>
                    <span className={(shipment.quote_transporter === undefined) ? classes.displayNone : ''}>
                        <img
                            className={classes.carrierLogo}
                            src={
                                shipment.quote_transporter === 'UPS' ? upsLogo
                                : shipment.quote_transporter === 'FedEx' ? fedexLogo
                                : (shipment.quote_transporter === 'TNT' || shipment.quote_transporter === 'SPRINGTNT') ? tntLogo
                                : shipment.quote_transporter === 'SPRING' ? springLogo
                                : shipment.quote_transporter === 'ARAMEX' ? aramexLogo
                                : shipment.quote_transporter === 'TRANSNAT' ? transnatLogo
                                : shipment.quote_transporter === 'FRETCARGO' ? fretcargoLogo
                                : ''
                            }
                            alt={`${shipment.quote_transporter} Logo`}
                        />
                    </span>
                    {shipment.is_ddp && <span><br />DDP<br /></span>}
                    <span className={classes.error}>
                        {shipment.pickup_request.error_message && <PopUpInfo message={shipment.pickup_request.error_message} config={errorConfig} />}
                    </span>
                    {shipment.coupon && <span>COUPON: {shipment.coupon}<br /></span>}
                    {discount()}
                    {shipment.offers_errors ? <span className={classes.error}><br />
                        <PopUpInfo message={shipment.offers_errors} config={{
                            color: 'red',
                            messageLength: 31,
                            header: 'Customer Error'
                        }} />
                    </span> : null}
                </TableCell>
                {/* <TableCell>
                    <DoneAllIcon fontSize="large" className={(shipment.admin_status === "ok" || shipment.admin_status === "pickup_error") ? "greenIcon" : ""} />
                </TableCell> */}
                {/* <TableCell>
                    (empty)
                </TableCell> */}
                <TableCell>
                    <Grid container>
                        {paymentMethodAndInvoice}
                        <Grid item xs={6} className="checkCircleIcon">
                            {/* <CheckIcon shipment={shipment} /> */}
                            {/* <DoneAllIcon fontSize="large" className={(shipment.admin_status === "ok" || shipment.admin_status === "pickup_error") ? "greenIcon" : ""} /> */}
                            {/* <CheckCircleIcon className="greenIcon" /> */}
                            <DoneAllIcon
                                fontSize="large"
                                className={shipment.has_pickup ? classes.doneAllIconGreen
                                : (shipment.admin_status === 'quote_selected') ? classes.doneAllIconBlack
                                : classes.doneAllIconGrey}
                                // className={(shipment.admin_status === "ok" || shipment.admin_status === "pickup_error") ? classes.doneAllIconGreen
                                // : (shipment.admin_status === 'quote_selected') ? classes.doneAllIconBlack
                                // : classes.doneAllIconGrey}
                            />
                        </Grid>

                                    {/* 
                                    
                                    <span key={i} className={classes.inlineFlex}><Tooltip title={u.file} placement="top"><a className={classes.headFiles} rel="noopener noreferrer" href={`${newadmindb}/basic/web/uploads/${u.id}/${u.file}`} target="_blank">{u.file.length > 9 ? `${u.file.slice(0, 8)}...` : u.file}</a></Tooltip> </span>
                                    
                                    */}

                        <Grid item xs={6} className={classes.gridCustomerAccount}>
                            <Tooltip
                                title={shipment._user ? 'Prendre le contrôle du compte' : 'No user account'}
                                placement="top"
                            >
                                {shipment.user_id ? <a className={classes.hrefCustomerAccount} rel="noopener noreferrer" href={`${urlExpedismart}/admin/auth/user/${shipment.user_id}/change/`}target="_blank"><GoToCustomerAccount fontSize="large" /></a>
                                : <IconButton className={classes.noUser} size="large"><GoToCustomerAccount fontSize="large" /></IconButton>}
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} className={classes.bugerIconGrid}>
                            <BurgerIcon shipment={shipment} setShipment={setShipment} />
                        </Grid>
                        <Grid item xs={6}>
                            {shipment.ikompar_invoice ? null : <GenerateInvoice />}
                        </Grid>
                        <Grid item xs={12}>
                            {(shipment.admin_status === "ok") ? "RESERVATION OK"
                            : (shipment.admin_status === "tracked_by_recipient") ? "FINALISATION ENVOI"
                            : (shipment.admin_status === "none") ? "SHIPMENT CREE"
                            : (shipment.admin_status === "quote_selected") ? "OFFRE CHOISIE"
                            : (shipment.admin_status === "payment_not_done") ? "PAIEMENT"
                            : (shipment.admin_status === "no_quotes") ? "PAS D’OFFRES"
                            : (shipment.admin_status === "discounted") ? "DISCOUNTED"
                            : (shipment.admin_status === "pickup_error") ? "PICKUP ERROR"
                            : ""}
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <TableRow className={`${classes.tableRowAdditional} ${shipment.admin_status}`}>
                <TableCell colSpan={8} >
                    <Grid container>
                        <Grid item xs={5} className={classes.username}>
                            <span className={classes.quoteServiceText}>{shipment.quote_service ? shipment.quote_service : ''}{shipment.quote_service ? ' | ' : ''}{!shipment.user ? '' : goToCustomerAccountButton}</span>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                title="Carriers"
                                aria-label="expand row"
                                className={classes.arrowDropIconButton}
                                onClick={() => {
                                    if(!open) getTransportInfo(shipment.id);
                                    setOpen(!open);
                                }}
                                size="large">
                                {open ? <ExpandLessIcon color="inherit" className={classes.arrowDropIcon} /> : <ExpandMoreIcon color="inherit" className={classes.arrowDropIcon} />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                </TableCell>
            </TableRow>
            <TableRow className={classes.transportInfo}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <colgroup>
                      <col span="1" style={{width: "14%"}} />
                      <col span="1" style={{width: "13%"}} />
                      <col span="1" style={{width: "11%"}} />
                      <col span="1" style={{width: "11%"}} />
                      <col span="1" style={{width: "12%"}} />
                      <col span="1" style={{width: "14%"}} />
                      <col span="1" style={{width: "26%"}} />
                    </colgroup>
                    <TableHead>
                      <TableRow className={classes.transportInfoHead}>
                        <TableCell className="floatLeft"><b>Carrier</b></TableCell>
                        <TableCell><b>Delivery Date</b></TableCell>
                        <TableCell><b>Delivered Time</b></TableCell>
                        <TableCell><b>Our Rate</b></TableCell>
                        <TableCell><b>Gross Margin</b></TableCell>
                        <TableCell><b>IKompar Nego Rate</b></TableCell>
                        <TableCell className="floatLeft"><b>Service</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.transportInfoBody}>
                      {transportInfo.map((item, i) => {
                        return (<React.Fragment key={i}>
                          <TableRow>
                            <TableCell className="floatLeft">
                                <span className={(item.transporter_name === undefined) ? classes.displayNone : ''}>
                                    <img
                                        className={classes.carrierLogoTransportInfo}
                                        src={
                                            item.transporter_name === 'UPS' ? upsLogo
                                            : item.transporter_name.toUpperCase() === 'FEDEX' ? fedexLogo
                                            : (item.transporter_name === 'TNT' || item.transporter_name === 'SPRINGTNT') ? tntLogo
                                            : item.transporter_name === 'SPRING' ? springLogo
                                            : item.transporter_name === 'ARAMEX' ? aramexLogo
                                            : item.transporter_name === 'TRANSNAT' ? transnatLogo
                                            : item.transporter_name === 'FRETCARGO' ? fretcargoLogo
                                            : ''
                                        }
                                        alt={`${item.transporter_name} Logo`}
                                    />
                                </span>
                            </TableCell>
                            <TableCell>{item.delivery_date}</TableCell>
                            <TableCell>{item.delivery_time}</TableCell>
                            <TableCell>{item.ikompar_quote.length ? numberThousands(item.ikompar_quote[0].amount) : ''}</TableCell>
                            <TableCell>{item.ikompar_quote.length ? grossMargin(item.ikompar_quote[0].amount, item.transporter_quote[0].amount_ikompar) : ''}</TableCell>
                            <TableCell>{numberThousands(item.transporter_quote[0].amount_ikompar)}</TableCell>
                            <TableCell className="floatLeft">{item.service_name}</TableCell>
                          </TableRow>
                        </React.Fragment>);
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
    );
}

function TablePaginationActions(props) {
    const { classes } = useStyles2();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large">
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
count: PropTypes.number.isRequired,
onPageChange: PropTypes.func.isRequired,
page: PropTypes.number.isRequired,
rowsPerPage: PropTypes.number.isRequired,
};

function ShipmentsBody(props) {
    const { classes } = useStyles();
    const { shipments,
        page,
        setPage,
        deleteShipment,
        getCustomerByIDAction,
        setGoToCA,
        caGoTo,
        pickupModify,
    } = props;
    const { countShipments,
        notFoundShipment,
        getShipmentLoading,
        dateNotSelected,
        fromDateBigger,
        searchShipment,
        handleCountShipments
    } = useContext(ShipmentsContext);
    // const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    useEffect(() => {
        setPage(0);
    }, [setPage, searchShipment]);

    useEffect(() => {
        // Counts how many shipments have (before-after filter)
        handleCountShipments(shipments.filter(ship => !deleteShipment.includes(ship.id)).filter(flt => (Object.values(flt).toString().toLowerCase().includes(searchShipment.toLowerCase()) || Object.values(flt.pickup_request).toString().toLowerCase().includes(searchShipment.toLowerCase()) || (flt.ikompar_invoice && Object.values(flt.ikompar_invoice).toString().toLowerCase().includes(searchShipment.toLowerCase())))).length);
    }, [handleCountShipments, shipments,
        deleteShipment, searchShipment,
    ]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countShipments - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const ifNotFoundShipment = (<TableRow>
        <TableCell colSpan={8} className={classes.loadingOrNotFound}>Not found any shipment, try to choose other dates.</TableCell>
    </TableRow>);

    const ifGetShipmentLoading = (<TableRow>
        <TableCell colSpan={8} className={classes.loadingOrNotFound}>Loading...</TableCell>
    </TableRow>);
    
    const ifDateNotSelected = (<TableRow>
        <TableCell colSpan={8} className={classes.loadingOrNotFound}>Please select date interval.</TableCell>
    </TableRow>);
    
    const ifFromDateBigger = (<TableRow>
        <TableCell colSpan={8} className={classes.loadingOrNotFound} style={{ color: 'red' }}>End date <u>cannot</u> be earlier than start date.</TableCell>
    </TableRow>);

    return (<div className={classes.root}>
        <TableContainer>
            <Table>
                <colgroup>
                    <col span="1" style={{width: "12%"}} />
                    <col span="1" style={{width: "10%"}} />
                    <col span="1" style={{width: "10%"}} />
                    <col span="1" style={{width: "18%"}} />
                    <col span="1" style={{width: "18%"}} />
                    <col span="1" style={{width: "10%"}} />
                    <col span="1" style={{width: "12%"}} />
                    {/* <col span="1" style={{width: "9%"}} /> */}
                    {/* <col span="1" style={{width: "6%"}} /> */}
                    {/* <col span="1" style={{width: "9%"}} /> */}
                    <col span="1" style={{width: "10%"}} />
                </colgroup>
                <TableHead>
                    <TableRow className={classes.tableHead}>
                        <TableCell className={classes.infoShipmentColumn}>Info Shipment</TableCell>
                        <TableCell>Création du shipment</TableCell>
                        <TableCell>Date pickup</TableCell>
                        <TableCell>Adresse d’enlèvement</TableCell>
                        <TableCell>Adresse de livraison</TableCell>
                        <TableCell>Type d’envoi</TableCell>
                        <TableCell>Transporteur</TableCell>
                        {/* <TableCell>User</TableCell> */}
                        {/* <TableCell>Docs envoyés</TableCell> */}
                        {/* <TableCell>Erreurs</TableCell> */}
                        <TableCell>Fonctionnalités</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.differentRows}>
                    {fromDateBigger ? ifFromDateBigger
                    : dateNotSelected ? ifDateNotSelected
                    : getShipmentLoading ? ifGetShipmentLoading
                    : notFoundShipment ? ifNotFoundShipment
                    : null}
                    {shipments.filter(ship => !deleteShipment.includes(ship.id)).filter(flt => (Object.values(flt).toString().toLowerCase().includes(searchShipment.toLowerCase()) || Object.values(flt.pickup_request).toString().toLowerCase().includes(searchShipment.toLowerCase()) || (flt.ikompar_invoice && Object.values(flt.ikompar_invoice).toString().toLowerCase().includes(searchShipment.toLowerCase())))).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(shipment => (<Row 
                        key={shipment.id}
                        shipmentOne={shipment}
                        getCustomerByIDAction={getCustomerByIDAction}
                        setGoToCA={setGoToCA}
                        caGoTo={caGoTo}
                        pickupModify={pickupModify}
                    />))}
                    {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[20, 30, 60]}
                colSpan={3}
                component="div"
                count={countShipments}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </TableContainer>
    </div>);
}

const mapStateToProps = state => {
    return {
        deleteShipment: state.deleteShipment,
        caGoTo: state.caGoTo,
        pickupModify: state.pickupModify,
    };
};

export default connect(mapStateToProps, {
    getCustomerByIDAction,
    setGoToCA,
})(ShipmentsBody);
