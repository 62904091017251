import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import BurgerPopUp from './BurgerPopUp';
import CorrespondencesPopUp from './CorrespondencesPopUp';
import CorrespondenceSegment from './CorrespondenceSegment';
import TableEditPopUp from './TableEditPopUp';
import IssueTypeEditPopUp from './IssueTypeEditPopUp';
import { convertDateToFrench,
  convertDateToWithDots,
  numberTwoDecimal,
  checkObject,
  seperateThousands
} from '../common/functions';
import IssueBodyContext from '../contexts/IssueBodyContext';
import { URL_All } from '../common/Constants';
import useToken from '../login/useToken';
import { setRefreshIssuesPage, setRefreshCard, setRefresh } from '../actions';
import PopUpPackage from '../tracking/PopUpPackage';
import PopUpAddress from '../tracking/PopUpAddress';
import axaPng from '../static/images/axa.png';
import postFinancePng from '../static/images/pf_icon.png';
import twintPng from '../static/images/twint_icon.png';
import { LastChecked, LastChecked2, InvoiceIcon, CreditCardIcon } from '../tracking/TrackingList';
import { IssuesContext } from '../contexts/IssuesContext';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
  issueCard: {
    backgroundColor: '#F7F7F7',
    marginBottom: theme.spacing(1),
    border: '1px solid #e5d2d2',
    borderRadius: 4,

    '& *': {
      padding: 0,
      margin: 0,
    },
    
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardTitle: {
    color: '#fff',
    backgroundColor: '#777',
    padding: theme.spacing(2),
    borderBottom: '1px solid #e5d2d2',
    display: 'flex',
    justifyContent: 'space-between',

    '& span': {
      fontWeight: '700',

      '& .red': {
        color: '#f00',
      },

      '&:first-of-type': {
        // display: 'flex',

      },
    },

  },
  cardBody: {
    paddingLeft: theme.spacing(1.5),
    
  },
  subSection: {    
    '&:not(:last-child)': {
      padding: '16px 4px',
      borderRight: '1px solid #e5d2d2',
    },

    '&:last-child': {
      height: '100%',

      '& > div': {
        height: '33%',
      },
    },
  },
  shipmentInfo: {
    '& .MuiGrid-root': {
      padding: '0 2px',
    },

    marginBottom: theme.spacing(3),
  },
  table: {
    maxWidth: 760,
    marginBottom: theme.spacing(1),

    '& thead': {
      backgroundColor: '#b8b8b8',

      '& tr th:not(:first-of-type)': {
        textAlign: 'center',
        fontWeight: '700',
      },
      
      '& tr th:first-of-type': {
        paddingLeft: theme.spacing(.5),
      },
    },

    '& tbody': {
      '& tr:nth-of-type(even)': {
        backgroundColor: '#ddd',
      },

      '& tr td:first-of-type': {
        fontWeight: '700',
        paddingLeft: theme.spacing(1.5),
      },
      
      '& tr td:not(:first-of-type)': {
        textAlign: 'center',
      },
    },
  },
  correspondenceButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  empty: {
    fontStyle: 'italic',
    color: '#555',
  },
  emails: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '90%',
  },
  insurance: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  divInlineBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),

    '& > div': {
      display: 'inline-block',
    },
  },
  serviceName: {
    marginTop: theme.spacing(1),
  },
  burgerPopUp: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  lastChecked: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '1rem .5rem',

    '& > div': {
      textAlign: 'center',

      '& > div:first-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
      },

      '& .showTooltip': {
        margin: 0,
        padding: 0,

        '& > div': {
          textOverflow: 'ellipsis',
          overflow: 'hidden', 
          width: '7rem', 
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  invoiceInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '& > p': {
      display: 'inline-block',
    },
  },
}});

const IssueCard = (props) => {
  const { classes } = useStyles();
  const { token } = useToken();
  const { title,
    issue,
    users,
    issuesCardRefresh,
    // setRefreshCard,
    setRefreshIssuesPage,
    issuesPageRefresh,
    refresh,
    setRefresh,
  } = props;
  const { statusValue } = useContext(IssuesContext);
  const { tracking } = useContext(IssueBodyContext);
  const [tasks, setTasks] = useState([]);
  const [navTransport, setNavTransport] = useState(0);
  const [navInsurance, setNavInsurance] = useState(0);
  const [navClient, setNavClient] = useState(0);
  const [taskLengthTransport, setTaskLengthTransport] = useState(0);
  const [taskLengthInsurance, setTaskLengthInsurance] = useState(0);
  const [taskLengthClient, setTaskLengthClient] = useState(0);
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  const dateNowRow = Math.trunc((new Date().getTime()) / 1000);

  useEffect(() => {
    getCommentTask(tracking.id);
  }, [issuesCardRefresh, tracking]);

  function getCommentTask(args) {
    fetch(`${URL_All.CommentTask}/get_shipment?shipment_id=${args}&sort=-id`)
      .then(response => response.json())
      .then(json => setTasks(json))
      .catch(error => console.error(error));
  };

  const editCommentTask = (id, taskStatus) => {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT', // GET, POST, PUT, DELETE
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ task_status: taskStatus, updated_by: currentUser })
    })
    .then(() => {
      // setRefreshKey(oldKey => oldKey + 1);
      getCommentTask(tracking.id);
    })
    .catch(error => console.error(error));
  };
  
  const deleteStatusAndSendToArvhive = (args) => {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: issue.shipment_id, is_actif: 0, checked_by: currentUser, checked_at: dateNowRow, archived_type: args, updated_by: currentUser})
    })
    .then(() => setRefreshIssuesPage(issuesPageRefresh + 1))
    .catch(error => console.error(error))
  };

  const deleteShipment = (args) => {
    if(!token) return logOut();
  
    fetch(URL_All.apiShipment + '/' + issue.shipment_id + args, {
      headers: {
        'authorization': `JWT ${token}`
      }
    })
      .then(response => {
        if (response.status === 403) {
          return logOut();
        }
      })
      .catch(error => console.error(error))
  }
  
  useEffect(() => {
    const filterTaskTransporteur = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 1));
    const filterTaskAssurance = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 2));
    const filterTaskClient = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 3));
    
    setTaskLengthTransport(filterTaskTransporteur.length);
    setTaskLengthInsurance(filterTaskAssurance.length);
    setTaskLengthClient(filterTaskClient.length);
  }, [issuesCardRefresh, tasks]);

  const navChangeTransport = (page) => {
    setNavTransport(page);
  };

  const navChangeInsurance = (page) => {
    setNavInsurance(page);
  };

  const navChangeClient = (page) => {
    setNavClient(page);
  };

  const displayTransporteur = () => {
    const filterTaskTransporteur = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 1)).sort(function(a, b) {return a.due_date - b.due_date;});

    if(filterTaskTransporteur.length) {
      return filterTaskTransporteur;
    }

    return [{
      id: 0,
      comment: '',
      task_status: 0,
      due_date: 0,
      to_users: 'undefined'
    }];
  };

  const displayAssurance = () => {
    const filterTaskAssurance = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 2)).sort(function(a, b) {return a.due_date - b.due_date;});
    if(filterTaskAssurance.length) {
      return filterTaskAssurance;
    }

    return [{
      id: 0,
      comment: '',
      task_status: 0,
      due_date: 0,
      to_users: 'undefined'
    }];
  };

  const displayClient = () => {
    const filterTaskClient = tasks.filter(task => (task.is_task === 1 & task.task_status !== 3 & task.with === 3)).sort(function(a, b) {return a.due_date - b.due_date;});
    if(filterTaskClient.length) {
      return filterTaskClient;
    }

    return [{
      id: 0,
      comment: '',
      task_status: 0,
      due_date: 0,
      to_users: 'undefined'
    }];
  };
    
  const statusCheckedBy = statusValue[tracking.id] !== undefined ? statusValue[tracking.id].checked_by : null;

  const lastCheckedUser = users.filter(filt => (filt.id === statusCheckedBy))[0] === undefined ? '' : users.filter(filt => (filt.id === statusCheckedBy))[0].username;

  const ifExist = statusValue[tracking.id] !== undefined ? true : false;

  const statusValueUpdatedTime = statusValue[tracking.id] !== undefined ? statusValue[tracking.id].checked_at : 0;

  // Invoice/Credit Card Icon on Hover data 
  const transporter_amount = Number.parseFloat(tracking.ikompar_invoice.transporter_amount).toFixed(2);
  const shipment_amount = Number.parseFloat(tracking.ikompar_invoice.shipment_amount).toFixed(2);
  const insurance_amount = Number.parseFloat(tracking.ikompar_invoice.insurance_amount).toFixed(2);
  const vat_amount = Number.parseFloat(tracking.ikompar_invoice.vat_amount).toFixed(2);
  const total_amount = Number.parseFloat(tracking.ikompar_invoice.total_amount).toFixed(2);
  
  const paymentMethodM = ((transporter_amount !== 'NaN' && shipment_amount !== 'NaN') ? Number.parseFloat((1 - transporter_amount / shipment_amount) * 100).toFixed(2) : null);

  function postStatus(statusId, highlighted, checkedAt) {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ shipment_id: tracking.id, status_id: statusId, highlighted: highlighted, is_actif: 1, checked_by: currentUser, checked_at: checkedAt, created_by: currentUser, updated_by: currentUser })
    })
    .then(() => setRefresh(refresh + 1))
    // .then(() => setRefreshIssuesPage(issuesPageRefresh + 1))
    .catch(error => console.error(error))
  };

  // editStatusLastChecked same with postStatus, because database checks if exist or not automaticly
  function editStatusLastChecked() {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ shipment_id: tracking.id, checked_by: currentUser, checked_at: dateNowRow, updated_by: currentUser })
    })
    .then(() => setRefresh(refresh + 1))
    // .then(() => setRefreshIssuesPage(issuesPageRefresh + 1))
    .catch(error => console.error(error))
  };
    
  const pickupAddressInfo = {
    type: 'Pickup',
    street_number: tracking.pickup_street_number,
    street_line_1: tracking.pickup_street_line_1,
    street_line_2: tracking.pickup_street_line_2,
    zip_code: tracking.pickup_zip_code,
    city: tracking.pickup_city,
    state: tracking.pickup_state,
    country_name: tracking.pickup_country_name,
    full_address: tracking.pickup_address,
  };

  const deliveryAddressInfo = {
    type: 'Delivery',
    street_number: tracking.delivery_street_number,
    street_line_1: tracking.delivery_street_line_1,
    street_line_2: tracking.delivery_street_line_2,
    zip_code: tracking.delivery_zip_code,
    city: tracking.delivery_city,
    state: tracking.delivery_state,
    country_name: tracking.delivery_country_name,
    full_address: tracking.delivery_address,
  };

  return (
    <Grid container className={classes.issueCard}>
      <Grid item xs={12} className={classes.cardTitle}>
        <span>
          Type de  cas:&nbsp;<span className="red"> 
          </span>&nbsp;<IssueTypeEditPopUp title={title} issue={issue} />
        </span>
        <span>
          Date déclaration client: {issue.date_customer === 0 ? '' : <span>{convertDateToWithDots(issue.date_customer * 1000)}</span>}
        </span>
        <span>
          Date déclaration assurance: {issue.date_insurance === 0 ? '' : <span>{convertDateToWithDots(issue.date_insurance * 1000)}</span>}
        </span>
        <span>
          Date déclaration transporteur: {issue.date_carrier === 0 ? '' : <span>{convertDateToWithDots(issue.date_carrier * 1000)}</span>}
        </span>
      </Grid>

      <Grid item xs={12} container className={classes.cardBody}>
        <Grid
          item
          xs={6}
          container
          className={classes.subSection}
        >
          <Grid item xs={12} container className={classes.shipmentInfo}>
            <Grid item xs={3}>
              <p><strong>Expéditeur:</strong></p>
              <span className={classes.contactCompanyNames}><b>{tracking.pickup_contact}</b></span><br />
              <span className={classes.contactNames}><b><i>{tracking.pickup_contact_name}</i></b></span><br />
              <PopUpAddress data={pickupAddressInfo} />
              <span>{tracking.pickup_phone}</span><br />
              <Tooltip
                placement="top"
                title={tracking.pickup_email}
              >
                <span className={classes.emails}><a href={`mailto:${tracking.pickup_email}`}>{tracking.pickup_email}</a></span>
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <p><strong>Destinataire:</strong></p>
              <span className={classes.contactCompanyNames}><b>{tracking.delivery_contact}</b></span><br />
              <span className={classes.contactNames}><b><i>{tracking.delivery_contact_name}</i></b></span><br />
              <PopUpAddress data={deliveryAddressInfo} />
              <span>{tracking.delivery_phone}</span><br />
              <Tooltip
                placement="top"
                title={tracking.delivery_email}
              >
                <span className={classes.emails}><a href={`mailto:${tracking.delivery_email}`}>{tracking.delivery_email}</a></span>
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <p><strong>Date de livraison estimée:</strong></p>
              <p>{convertDateToFrench(tracking.delivery_date)}</p>
              <p><strong>Date de livraison réelle:</strong></p>
              <p>(empty)</p>
              <p><strong>Signé par:</strong></p>
              <p>(empty)</p>
            </Grid>
            
            <Grid item xs={3}>
              <div className={classes.invoiceInfo}>
                <p><strong>Facture n°:</strong></p>
                <span className={classes.iconAtSameLine}>
                  <Tooltip
                    className={classes.paymentMethod}
                    title={<div>
                        Transporter CHF: {transporter_amount !== 'NaN' && transporter_amount}
                        <br />Shipment CHF: {shipment_amount !== 'NaN' && shipment_amount}
                        <br />GM (%): {paymentMethodM !== 'Infinity' && paymentMethodM}
                        <br />Insurance CHF: {insurance_amount !== 'NaN' && insurance_amount}
                        <br />Vat CHF: {vat_amount !== 'NaN' && vat_amount}
                        <br />Total CHF: {total_amount !== 'NaN' && total_amount}
                      </div>}
                    placement="right-end"
                  >
                    <IconButton size="large">
                      {(tracking.ikompar_invoice === null) ? null : (tracking.ikompar_invoice.payment_method === 'CreditCard') ? <CreditCardIcon /> : (tracking.ikompar_invoice.payment_method === 'Invoice') ? <InvoiceIcon />
                        : ((tracking.ikompar_invoice.payment_method === 'Postfinance card') || (tracking.ikompar_invoice.payment_method === 'Postfinance e-finance') || (tracking.ikompar_invoice.payment_method === 'PF Pay')) ? <Avatar
                          variant="square"
                          alt={!tracking.ikompar_invoice ? 'Icon' : tracking.ikompar_invoice.payment_method}
                          src={postFinancePng}
                        /> : (tracking.ikompar_invoice.payment_method === 'Twint') ? <Avatar
                          variant="square"
                          alt={!tracking.ikompar_invoice ? 'Icon' : tracking.ikompar_invoice.payment_method}
                          src={twintPng}
                      /> : null}
                    </IconButton>
                  </Tooltip>
                </span>                
              </div>
              <p>{tracking.ikompar_invoice.number}</p>
              <p><strong>AWB:</strong></p>
              <p>{tracking.ikompar_invoice.airwaybill.replace(/<br\/>/g, "\n")}</p>
              <br />
              <div className={classes.divInlineBlock}>
                <PopUpPackage item={tracking} />
                {tracking.is_insured ? <Avatar
                  className={classes.insurance}
                  variant="square"
                  alt="Axa Insurance" src={axaPng}
                /> : null}
              </div>
              <Tooltip
                title={tracking.quote_service}
                placement="bottom"
              >
                <p className={classes.serviceName}>
                  {(tracking.quote_service.length > 16) ? `${tracking.quote_service.slice(0, 16)}...` : tracking.quote_service}
                </p>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <colgroup span="1" style={{ width: "40%" }} />
              <colgroup span="1" style={{ width: "20%" }} />
              <colgroup span="1" style={{ width: "20%" }} />
              <colgroup span="1" style={{ width: "20%" }} />
              <TableHead>
                <TableRow>
                  <TableCell><TableEditPopUp issue={issue} /></TableCell>
                  <TableCell>Database</TableCell>
                  <TableCell>A rembourser</TableCell>
                  <TableCell>remboursé</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Prix d’achat</TableCell>
                  <TableCell>
                      {checkObject(tracking.ikompar_invoice) ? numberTwoDecimal(tracking.ikompar_invoice.transporter_amount) : ''}
                    </TableCell>
                  <TableCell>{seperateThousands(issue.purchase_to_refund)}</TableCell>
                  <TableCell>{seperateThousands(issue.purchase_refunded)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Prix de vente (with insurance amount)</TableCell>
                  <TableCell>
                    {checkObject(tracking.ikompar_invoice) ? numberTwoDecimal(tracking.ikompar_invoice.total_amount) : ''}
                  </TableCell>
                  <TableCell>{seperateThousands(issue.sale_to_refund)}</TableCell>
                  <TableCell>{seperateThousands(issue.sale_refunded)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Valeur marchandises</TableCell>
                  <TableCell>
                    {checkObject(tracking.package_group) ? numberTwoDecimal(tracking.package_group.customs_total_value) : ''} {(tracking.custom_currency && tracking.custom_currency !== 'CHF') ? tracking.custom_currency : ''}
                  </TableCell>
                  <TableCell>{seperateThousands(issue.customsvalue_to_refund)}</TableCell>
                  <TableCell>{seperateThousands(issue.customsvalue_refunded)}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Remboursement client</TableCell>
                  <TableCell className={classes.empty}>(empty)</TableCell>
                  <TableCell>{seperateThousands(issue.customer_to_refund)}</TableCell>
                  <TableCell>{seperateThousands(issue.customer_refunded)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Grid item xs={5} className={classes.subSection}>
          <div className={classes.correspondenceButton}>
            <CorrespondencesPopUp
              goToTab={0}
              buttonTitle="CORRESPONDANCES"
            />
          </div>
          <CorrespondenceSegment
            title="Transporteur"
            goToTab={1}
            message={displayTransporteur()[navTransport].comment}
            buttonOneText="Non traité"
            buttonTwoText="traité"
            taskStatus={displayTransporteur()[navTransport].task_status}
            selectedButton={editCommentTask}
            taskId={displayTransporteur()[navTransport].id}
            navCurrent={navTransport}
            navLength={taskLengthTransport}
            navChange={navChangeTransport}
            dueDate={displayTransporteur()[navTransport].due_date}
            toWho={displayTransporteur()[navTransport].to_users}
          />
          <CorrespondenceSegment
            title="Assurance"
            goToTab={2}
            message={displayAssurance()[navInsurance].comment}
            buttonOneText="En attente"
            buttonTwoText="Répondu"
            taskStatus={displayAssurance()[navInsurance].task_status}
            selectedButton={editCommentTask}
            taskId={displayAssurance()[navInsurance].id}
            navCurrent={navInsurance}
            navLength={taskLengthInsurance}
            navChange={navChangeInsurance}
            dueDate={displayAssurance()[navInsurance].due_date}
            toWho={displayAssurance()[navInsurance].to_users}
          />
          <CorrespondenceSegment
            title="Client"
            goToTab={3}
            message={displayClient()[navClient].comment}
            buttonOneText="En attente"
            buttonTwoText="Répondu"
            taskStatus={displayClient()[navClient].task_status}
            selectedButton={editCommentTask}
            taskId={displayClient()[navClient].id}
            navCurrent={navClient}
            navLength={taskLengthClient}
            navChange={navChangeClient}
            dueDate={displayClient()[navClient].due_date}
            toWho={displayClient()[navClient].to_users}
          />
        </Grid>

        <Grid item xs={1} container className={classes.subSection}>
          <Grid item xs={12} />
          <Grid item xs={12} className={classes.burgerPopUp}>
            <BurgerPopUp
              shipmentId={tracking.id}
              deleteShipment={deleteShipment}
              deleteStatusAndSendToArvhive={deleteStatusAndSendToArvhive}
            />
          </Grid>
          <Grid item xs={12} className={classes.lastChecked}>
            <div>
              <LastChecked
                updatedTime={statusValueUpdatedTime}
                lastCheckedUser={lastCheckedUser}
                ifExist={ifExist}
                editStatusLastChecked={editStatusLastChecked}
                postStatus={postStatus}
              />
              <Tooltip
                title={lastCheckedUser}
                placement="bottom"
              >
                <div className="showTooltip">
                  <LastChecked2
                    updatedTime={statusValueUpdatedTime}
                    lastCheckedUser={lastCheckedUser}
                  />
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return { users: state.users,
    issuesCardRefresh: state.issuesCardRefresh,
    issuesPageRefresh: state.issuesPageRefresh,
    refresh: state.refresh,
  };
};

export default connect(mapStateToProps, {
  setRefreshIssuesPage,
  setRefreshCard,
  setRefresh,
})(IssueCard);
