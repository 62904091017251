import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ShipmentType from '../fields/ShipmentType';
import MonthsPeriod from '../fields/MonthsPeriod';
import YearsComparison from '../fields/YearsComparison';
import Display from '../fields/Display';
import PropTypes from 'prop-types'
import Pareto from '../fields/Pareto';
import ConversionFunnels from '../fields/ConversionFunnels';
import { FormControlLabel, Switch } from '@mui/material';
import Activity from '../fields/Activity';

const useStyles = makeStyles()((theme) => { return {
	headerSelector: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		gap: '6px',
		paddingTop: theme.spacing(2),
	},
	twoRows: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
		width: '100%',
	},
}});

function StatisticsSellingsControls({
	shipmentType,
	monthsPeriod,
	yearsComparison,
	display,
	activity,
	setShipmentType,
	setMonthsPeriod,
	setYearsComparison,
	setDisplay,
	setActivity,
	conFunnels,
	setConFunnels,
	delta,
	setDelta
}) {
	const { classes } = useStyles();

	return (
		<div className={classes.headerSelector}>
			<ShipmentType selected={shipmentType} setSelected={setShipmentType} />
			<MonthsPeriod selected={monthsPeriod} setSelected={setMonthsPeriod} />
			<YearsComparison selected={yearsComparison} setSelected={setYearsComparison} />
			<Activity selected={activity} setSelected={setActivity} />
			<div className={classes.twoRows}>
				<Display selected={display} setSelected={setDisplay} />
				<ConversionFunnels selected={conFunnels} setSelected={setConFunnels} />
				<FormControlLabel 
					label="Delta (%)"
					control={<Switch 
						checked={delta}
						onChange={() => setDelta(!delta)}
						color="primary"
					/>}
				/>
			</div>
		</div>
	);
}

StatisticsSellingsControls.propTypes = {
	shipmentType: PropTypes.number,
	monthsPeriod: PropTypes.arrayOf(PropTypes.object),
	yearsComparison: PropTypes.arrayOf(PropTypes.object),
	display: PropTypes.number,
	activity: PropTypes.number,
	setShipmentType: PropTypes.func,
	setMonthsPeriod: PropTypes.func,
	setYearsComparison: PropTypes.func,
	setDisplay: PropTypes.func,
	setActivity: PropTypes.func,
	conFunnels: PropTypes.number,
	setConFunnels: PropTypes.func,
	delta: PropTypes.bool,
	setDelta: PropTypes.func
}

export default StatisticsSellingsControls;